import React from 'react';
import {Helmet} from 'react-helmet';
import PageTitle from '../../Shared/Page/pageTitle';

const VehicleCheckGuarantee = () => (

    <>
        <Helmet>
            <title>Vehicle Check Guarantee</title>
            <meta name="description" content="autoClarity vehicle check guarantee details" />
            <link rel="canonical" href="http://autoclarity.co.uk/policies/vehicle-check-guarantee" />
        </Helmet>
        <PageTitle title='Vehicle Check Guarantee' lead="Terms and Conditions of our Full Check Guarantee" />
        <div className='container'>
            <p>
                The Full Check Guarantee provides protection to you up to the maximum sum of £40,000 for losses incurred by you due to our failure to accurately report
                data from our sources that was available to us.

                The Full Check Guarantee is only applicable if <strong>all</strong> the following conditions and obligations are complied with fully. 
            </p>

            <h5>Definitions</h5>
            <ul>
                <li><strong>Us</strong>: ClearCut IT Ltd t/a autoClarity.</li>
                <li><strong>You</strong>: A person seeking to purchase a vehicle for their own personal use.</li>
                <li><strong>Designated Vehicle</strong>: The UK registered vehicle identified by the registration mark you provided us, for which you purchased a check from us and which you subsequently purchased.</li>
                <li><strong>Private Seller</strong>: A person, executor or business not involved in the motor trade who is selling a vehicle owned by them and they are named as the registered keeper at the time of the sale to You.</li>
                <li><strong>Trade Seller</strong>: A person or business that sells motor vehicles that they have not purchased as a private Seller. A Trade Reseller trades from bona fide business premises and is registered for VAT.</li>
                <li><strong>Working Hours</strong>: The 8 hours between 9am and 5pm on Mondays to Fridays, excluding UK public holidays</li>
            </ul>

            <h5 className='mt-5'>Categories of claims applicable to this guarantee</h5>
            <h6>Type A: Stolen Vehicle</h6>
            <p>
                A claim under this section may be made if we stated in our report to You the Designated Vehicle was not reported as stolen 
                and it transpires it had already been reported to the police and recorded upon the Police National Computer.
            </p>
            
            <h6>Type B: Insurance Write Off (Categories A-D, S or N)</h6>
            <p>
                A claim under this section may be made if we stated in our report to you that the Designated Vehicle was not recorded on the MIAFTR database as being recorded 
                as being a write off category (A-D, S or N) when it transpires that it had been recorded as a write off on the MIAFTR by an insurer.
            </p>
            <p>
                A claim under this section may also be made if we stated in our report to you that the Designated Vehicle was a write off category of a category lower (e.g. "D" rather than "A") than was in fact the case.
            </p>
            <p>
                In both instances for a claim to be made under this section the data which we failed to report, or failed to report correctly must have been recorded on the MIAFTR database.
            </p>

            <h6>Type C: DVLA &amp; Mileage Data</h6>
            <p>
                A claim under this section may be made if we stated in our report that data we have obtained from DVLA is incorrectly provided to you. 
                For the avoidance of doubt, we do not offer any guarantee on the accuracy of DVLA data, our guarantee to You is that the data we report to you is an accurate portrayal of what the DVLA provided to us.
            </p>
            <p>
                A claim under this section may also be made if We failed to accurately report to you the mileage records we have obtained from various third parties. 
                For the avoidance of doubt we have no control on the accuracy of mileage records and our guarantee is only that we have supplied to you correctly the data which has been provided to us.
            </p>

            <h6>Type D: Finance</h6>
            <p>
                A claim under this section may be made if we failed to report to you a recorded finance charge on the Experian Finance Register.  
            </p>
            <p>
                For the avoidance of doubt it is not a legal requirement that all finance houses record their finance charges and our guarantee only applies to charges which were recorded on the Experian Finance Register which we failed to report.
            </p>

            <h5 className='mt-5'>
                Your Obligations 
            </h5>
            <ol>
                <li>
                    You must have viewed and diligently inspected the whole of the V5 relating to the Designated Vehicle. You must make and retain a copy of the V5 before You complete the purchase.
                </li>
                <li>
                    You must keep safe a copy of any advert you have seen for the Designated Vehicle. If an internet based advert you should either take screen dump(s) 
                    and save it in a document or print out the advert in its entirety.
                </li>
                <li>
                    You must receive a signed and dated receipted bill of sale from the seller.
                </li>
                <li>
                    You must limit any cash transaction in respect of the designated vehicle to a maximum of either £500 or 10% of the sale price of the Designated Vehicle whichever is the lower.
                </li>
                <li>
                    When purchasing from a private seller you must:
                    <ul>
                        <li>
                            Verify the identity of the seller (you should inspect a photo Driving Licence or Passport ). 
                            If this is not available but some other identity is offered contact Us first for verification this is acceptable within the terms of this guarantee.
                        </li>
                        <li>
                            Verify the seller is the current registered keeper and the seller resides at the address stated on the V5. 
                            You must view a current utility bill and record the account number or reference from it. 
                            If you have not purchased from the registered keeper or a bona fide trade seller operating from trade premises no claim will be accepted under this guarantee.
                        </li>
                        <li>
                            You must specifically ask the seller if the vehicle has been an insurance write off or has any attaching finance charge and decline to purchase if this information contradicts our report.
                        </li>
                    </ul>
                </li>
                <li>
                    When making payment (other than a deposit) you must use one of the following UK methods. 
                    <strong>Transfers using money transfer Companies (e.g. Western Union) or any email or internet accessible electronic transfers are not acceptable methods of payment and using them will prevent you from making a claim under this guarantee.</strong>
                
                    <ul>
                        <li>
                            By crossed cheque payable to the registered keeper named on the V5 from an account held by You
                        </li>
                        <li>
                            By direct bank transfer to a UK account in the name of the registered keeper named on the V5 and from an account in your name.
                        </li>
                        <li>
                            By Debit or Credit card where the merchant shown on the credit/debit card receipt is the registered keeper named on the V5 or the bona fide dealer from whom you are purchasing.
                        </li>
                        <li>
                            You paying in a UK Bankers Draft into a UK account in the name of the registered keeper named on the V5. A stamped receipt from the Bank to verify the details of the transaction must be retained.
                        </li>
                        <li>
                            A Finance Agreement taken out in your name by a member of the Finance and Leasing Association and where payment was made directly to a trade seller.
                        </li>
                    </ul>

                </li>

                <li>
                    When purchasing from a trade seller you must:
                    <ul>
                        <li>
                            Take reasonable and prudent care to ensure you are purchasing from a bona fide motor dealer and ensure the transaction takes place at bona fide trade premises.
                            For the avoidance of any doubt, transactions occurring in premises which are not clearly identifiable as a business selling vehicles with appropriate signage will disqualify You from making a claim.
                        </li>
                        <li>
                            Specifically ask the seller if the vehicle has been an insurance write off, been involved in an accident or has any attaching finance charge. If this information contradicts our report you should decline to purchase the vehicle.
                        </li>

                    </ul>
                </li>

                <li>
                    You must register the designated vehicle in your name and insure and tax the vehicle immediately after purchase. A vehicle without a current MOT at the time of your purchase is excluded from this guarantee.
                </li>

                <li>
                    When accepting payment or other settlement from us under the terms of this guarantee you irrevocably transfer to us any and all rights to pursue compensation or other value from the person or organisation you purchased the Designated Vehicle from. 
                    You agree to provide us with any and all evidence necessary for us to reasonably pursue that claim.
                </li>

                <li>
                    There is no guarantee if You purchased the vehicle for an unreasonable price. It is deemed unreasonable if you paid less or more than 70% of the published applicable value in Glass’ Guide at the time of the purchase.
                </li>
            </ol>

            <h5 className='mt-5'>
                Approved claims handling policy 
            </h5>
            <p>
                The following sets out how a claim approved by us under this guarantee will be handled.
            </p>

            <h6>Claims Type A</h6>
            <p>
                We will pay you the same amount you paid for the vehicle, or the reasonable open market value (whichever is the lower) if 
                the vehicle has been impounded by the police and the police have either returned the vehicle to another person or are in the process of so doing.
            </p>

            <h6>Claims Type B</h6>
            <p>
                We will pay you either (at our discretion):
            </p>
            <ol>
                <li>
                    The open market trade value of the Designated Vehicle adjusted in line with the insurance category we stated existed as opposed to the one which actually applied, 
                    or the amount you paid for the vehicle, whichever is the lower, as full and final payment.
                </li>
                <li>
                    An amount of money as compensation being equal to the mean average of the reasonable diminution in the trade and private sale value of the Designated Vehicle at the time of the claim. 
                    The diminution calculation is strictly that attributable to the differences in value which exists solely as a result of incorrect insurance classification provided. As guidance a Category D classification typically reduces the value of an otherwise identical vehicle with no classification by 10% and Category C by 15%. Category B vehicles are valued as for spare parts only and a Category A as scrap (to be destroyed).
                </li>
            </ol>
           
            <h6>Claims Type C</h6>
            <p>
                We will pay you compensation being equal to the mean average of the reasonable diminution in the trade and private sale value of the designated vehicle at the time of the claim. 
                The diminution calculation is strictly that attributable to the differences in value which exists solely as a result of incorrect data supplied by us.
            </p>

            <h6>Claims Type D</h6>
            <p>
                You will grant us your full authority to negotiate a settlement with the Finance Company involved and we may attempt to settle the finance charge in order to allow you to gain full title to the designated vehicle. 
                If we achieve this, your claim is settled in full.
            </p>
            <p>
                If an agreement cannot be reached with the Finance Company, we will pay you an amount equal to either the reasonable private sale value of the Designated Vehicle at the time of the claim, or the amount you paid for the vehicle, whichever is the lower.
            </p>

            <h5 className='mt-5'>
                General exclusions not specified above - When no payment will be made under this guarantee
            </h5>
            <ol>
                <li>
                    If you knew, you reasonably should have known, or had reasonable cause to suspect the data we provided in the report on which you relied when making a claim was incorrect prior to you purchasing the designated vehicle.
                </li>
                <li>
                    If you are involved with the motor trade in any capacity.
                </li>
                <li>
                    You fail to provide timely accurate and full information to us when making a claim.
                </li>
                <li>
                    If you willingly disposed of the Designated Vehicle prior to our investigation into your claim being complete.
                </li>
                <li>
                    You misrepresent any facts to us at any time.
                </li>
                <li>
                    If you failed to take reasonable and prudent care.
                </li>
                <li>
                    If you failed to act ethically or with good faith at all times in respect of all matters concerning the Designated Vehicle, its use, purchase etc. and/or any claim to Us.
                </li>
                <li>
                    If any data printed on the V5 which you either saw (or should have seen) prior to purchase of the Designated Vehicle contradicts any data from our report.
                </li>
                <li>
                    A Misrepresented Vehicle. This is a vehicle which carries a registration mark which is not the correct one for the vehicle. This is sometimes known as a "cloned" or "ringed" vehicle.  
                    We offer no guarantee to our customers in connection with misrepresented vehicles and all misrepresented vehicles are specifically excluded from this guarantee.
                </li>
                <li>
                    Any amount or value in excess of the guarantee limit we have provided to you.
                </li>
                <li>
                    Any claim where you have failed to follow your obligations or met the definitions as detailed herein.
                </li>
                <li>
                    This guarantee is not transferrable to any person or organisation.
                </li>
                <li>
                    Any claim in respect of consequential loss of any kind whether such loss is foreseeable or not.
                </li>
            </ol>
            
        </div>

    </>
)

export default VehicleCheckGuarantee;