import React, {useState} from 'react'
import Links from '../Shared/ButtonLinks'
import {Card, CardDeck, Jumbotron, Collapse, Button, Image, Container, Col, Row} from 'react-bootstrap'
import './landing.css';

import CheckImage from '../../assets/AC_Check.png'
import ValueImage from '../../assets/AC_value.png'
import OwnImage from '../../assets/AC_own.png'
import ValuationExample from '../../assets/valuation.svg'
import * as ROUTES from '../../constants/routes'
import { useFetchAllProducts } from '../Hooks/useFetchAllProducts'
import { ProductCardDeck } from '../Products/productCard'
import { PageSpinner } from '../Shared/Page/pageSpinner'
import { LinkContainer } from 'react-router-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Redirect } from 'react-router-dom'
import TimelineImage from '../../assets/timeline_hand.png'
import VehicleSummaryImage from '../../assets/summary_hand.png'
import ValueHandImage from '../../assets/valuation_hand.png'
import ExperianLogo from '../../assets/experian_gray.png'
import AbiLogo from '../../assets/abi_gray.png'
import { Link } from 'react-router-dom'

const RedColor = "#ff1342"

const LandingCard = (props) => (
  <Card className="text-center">
  <Card.Img variant="top" src={props.img}/>  
  <Card.Body>
    <Card.Title>{props.title}</Card.Title>
    <Card.Text>
      {props.text}
    </Card.Text>
    <LinkContainer to={props.route} >
      <Button variant="primary">{props.buttonTitle}</Button>
    </LinkContainer>
    
  </Card.Body>
</Card>
)

const TestimonialCard = (props) => (
  <Card className="text-center border-info"> 
  <Card.Body>
    <Card.Text>
      <FontAwesomeIcon icon="star" color="#FFD700"/>
      <FontAwesomeIcon icon="star" color="#FFD700"/>
      <FontAwesomeIcon icon="star" color="#FFD700"/>
      <FontAwesomeIcon icon="star" color="#FFD700"/>
      <FontAwesomeIcon icon="star" color="#FFD700"/>
    </Card.Text>
    <Card.Text className='small'>
      {props.text}
    </Card.Text>
    <Card.Text className='small text-muted'>
      {props.who} - {props.when}
    </Card.Text>
  </Card.Body>
</Card>
)



 const FAListItem = (props) => (
  <FontAwesomeIcon icon={props.icon} color={RedColor} listItem/>
 )


const Landing = (props) => {

  const [showMore, setShowMore] = useState(false)
  const {error: productsError, loading: productsLoading, fullCheckProducts} = useFetchAllProducts()
  const [product, setProduct] = useState(null)

  const onClickHandler = (productKey) => {
    setProduct({productKey: productKey})
  }

  const ShowValuationButton = () => (
    <div>
    <Button variant="primary" onClick={() => setShowMore(!showMore) }>{!showMore ? 'See Example' : 'Hide Example'}</Button>
    <Collapse in={showMore}>
      <div className='mt-2'>
          <Image src={ValuationExample} fluid />
      </div>
    </Collapse>
    </div>
  )

  if (product) {
    return <Redirect push to={{
        pathname:ROUTES.CHECKOUT,
        state:{
            productKey:product.productKey
         }
       }} />
  }
  
  return (

    

    <>
      <section id='landing-header' className='landing-header mb-3 text-center'>
        <h1 className='text-xl-center header text-light px-1'>autoClarity: Car Check &amp; Value</h1>
        <h5 className='text-light px-1'>Car Checks &bull; Valuations &bull; Free Checks &bull; On Your iPhone and iPad</h5>
        <Links.AppDownloadButton/>
      </section>


      <section id='check-types'>
      <Container>
          <Row>
              <Col md={6} className='px-0'>
                  <Card.Img src={VehicleSummaryImage} alt="vehicle timeline app screenshot image" />
              </Col>
              <Col md={6}>
                  <Card.Body >
                      <FontAwesomeIcon icon="rocket" color={RedColor} size="3x" pull="right"/>
                      <Card.Title className="text-center">Free Vehicle Checks</Card.Title>
                      <Card.Text>
                      autoClarity: Car Check &amp; Value app for iPhone gives you free car checks that you can use to reveal:
                      </Card.Text>
                      <ul className='fa-ul'>
                        <li><FAListItem icon="rocket"/>DVLA Registration Information</li>
                        <li><FAListItem icon="rocket"/>Vehicle Description</li>
                        <li><FAListItem icon="rocket"/>Full MOT History including failure reasons and advisories</li>
                        <li><FAListItem icon="rocket"/>Previous Keepers</li>
                        <li><FAListItem icon="rocket"/>ULEZ Compliance</li>
                        <li><FAListItem icon="rocket"/>Performance and Consumption and more...</li>
                      </ul>
                      
                      <Card.Text>
                        <LinkContainer to={ROUTES.FREE_CAR_CHECK} >
                          <Button>More Information</Button>
                        </LinkContainer>
                      </Card.Text>
                      
                  </Card.Body>       
              </Col>
          </Row>
          <Row>
              <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }}>
                  <Card.Body >
                      <FontAwesomeIcon icon="meteor" color={RedColor} size="3x" pull="right"/>
                      <Card.Title className="text-center">Paid Vehicle Checks</Card.Title>
                      <Card.Text>
                      autoClarity: Car Check &amp; Value app for iPhone offers paid checks that can reveal more history including: 
                      </Card.Text>
                      <ul className='fa-ul'>
                        <li><FAListItem icon="meteor"/>Outstanding Finance</li>
                        <li><FAListItem icon="meteor"/>Stolen Records</li>
                        <li><FAListItem icon="meteor"/>Write Off - Cat S/N A/B/C/D</li>
                        <li><FAListItem icon="meteor"/>Mileage Anomalies</li>
                        <li><FAListItem icon="meteor"/>3rd Party High Risk Alerts</li>
                        <li><FAListItem icon="meteor"/>Scrapped</li>
                      </ul>
                      <LinkContainer to={{ pathname: ROUTES.PRODUCTS, hash: "#checkCompare" }} >
                        <Button>Compare Checks</Button>
                      </LinkContainer>
                  </Card.Body>       
              </Col>
              <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }} className='px-0'>
                  <Card.Img src={TimelineImage} alt="vehicle timeline app screenshot image" />
              </Col>
          </Row>
          <Row>
              <Col md={6} className='px-0'>
                  <Card.Img src={ValueHandImage} alt="vehicle valuation app screenshot image" />
              </Col>
              <Col md={6}>
                  <Card.Body >
                      <FontAwesomeIcon icon="burn" color={RedColor} size="3x" pull="right"/>
                      <Card.Title className="text-center">Valuations</Card.Title>
                      <Card.Text>
                      autoClarity: Car Check &amp; Value app for iPhone valuations show market wide sales figures covering:
                      </Card.Text>
                      <ul className='fa-ul'>
                        <li><FAListItem icon="burn"/>Dealer - Part X and Forecourt</li>
                        <li><FAListItem icon="burn"/>Private - Average and Clean Condition</li>
                        <li><FAListItem icon="burn"/>Trade - Poor, Average and Retail</li>
                        <li><FAListItem icon="burn"/>Auction</li>
                        <li><FAListItem icon="burn"/>On The Road</li>
                      </ul>
                      
                      <ShowValuationButton/>
                      
                  </Card.Body>       
              </Col>
          </Row>
      </Container>
      </section>

      <section id='app-features'>
      <Jumbotron fluid >
        <Container>
          <div className='text-center'>
            <h2>App Features</h2>
            <p className='mx-5'>autoClarity: Car Check &amp; Value app has great usability features including:</p>
          </div>
          <Row>
            <Col md={{ span: 4, offset: 2 }} className='d-none d-sm-block'>
            <div className='features-image-stack'>
              <FontAwesomeIcon icon={['fab', 'facebook']} size="6x"/>
              <FontAwesomeIcon icon="video" size="6x"/>
              <FontAwesomeIcon icon="microphone-alt"  size="8x"/>
            </div>  
            </Col>
            <Col sm={12} md={6}>
              <Card.Body >
              <ul className='fa-ul'>
                <li><FAListItem icon="burn"/>Automatic Number Plate Recognition Technology</li>
                <li><FAListItem icon="burn"/>Voice Number Plate Recognition Technology</li>
                <li><FAListItem icon="burn"/>Simple sign in / sign up using Apple, Facebook or Phone number</li>
                <li><FAListItem icon="burn"/>Reminders of your MOT, Tax, service and insurance due dates</li>
                <li><FAListItem icon="burn"/>Add pictures and notes to your saved vehicle checks and valuations</li>
              </ul>
              </Card.Body>
            </Col>
          </Row>
          <div className = 'text-center mt-3'>
            <Links.AppBanner/>
          </div>
        </Container>
      </Jumbotron>
      </section>

      <section className='text-center mt-5'>
      <Container>
        <h2>Our Full Check Prices</h2>
        <p className='mx-5'>
          Comprehensive Full Checks of vehicle history that you can rely on. We offer different bundle sizes at great prices. There are no up-sells, we just show all the
          information we have available on the vehicle.
        </p>
        <div className='mx-3'>
        {productsLoading &&
            <PageSpinner/>
        }

        {productsError &&
            <p className='text-danger'>Error loading products, try refreshing the page.</p>
        }

        {!productsLoading && fullCheckProducts && 
            <ProductCardDeck products={fullCheckProducts} onClickHandler={onClickHandler}/>
        }  
        </div>

        <div className='text-center'>
          <LinkContainer to={ROUTES.PRODUCTS}>
            <Button className='mx-1' variant="primary">See All Products</Button>
          </LinkContainer>
        </div>
      </Container>   
      </section>
      
      <section id="landing-cards" className='mt-5 container' >
            <div className='text-center'>
                  <h2>Check It &bull;  Value It &bull; Own It</h2>
                  <p className='mx-5'>autoClarity: Car Check &amp; Value app is the essential tool in your pocket</p>
            </div>
            <CardDeck className="mx-auto">
                <LandingCard 
                  img={CheckImage}
                  title="Vehicle Checks"
                  text="Download the app to check vehicle history. Use our Full Check service to get a comprehensive report backed up by our guarantee."
                  route={ROUTES.PRODUCTS}
                  buttonTitle="View Products">
                </LandingCard>

                <Card className="text-center" >
                  <Card.Img variant="top" src={ValueImage}/>  
                  <Card.Body>
                    <Card.Title>{"Vehicle Valuations"}</Card.Title>
                    <Card.Text>
                      Download the app to get a valuation. We report market data to show you values for trade, private and auction sales.
                    </Card.Text>
                    
                    <ShowValuationButton/>
                  </Card.Body>
                </Card>

                <LandingCard 
                  img={OwnImage}
                  title="Vehicle Reminders"
                  text="Download the app, lookup your vehicles and add your reminders. Get notifications on your device for all your vehicles, and never miss those important due dates."
                  route={ROUTES.VEHICLE_REMINDERS}
                  buttonTitle="More">
                </LandingCard>
            </CardDeck>
      </section>


      <section id='why-choose'>
      <Jumbotron fluid className='text-center mb-0'>
        <Container>
        <h2>Why Choose autoClarity: Car Check &amp; Value?</h2>
        <p className='mx-2'>
          Whether you are a private owner/buyer, or in the motor trade, autoClarity: Car Check &amp; Value
          app has all the vehicle information you need, thoughtfully presented.
        </p>

        <p className='mx-2'>
          We supply HPI type <Link to={{pathname: ROUTES.VEHICLE_CHECK_GUARANTEE}}>guaranteed</Link> vehicle history checks and market valuations on your iPhone or iPad.
        </p>

        <p className='mx-2'>
          Discover for yourself by downloading the autoClarity: Car Check &amp; Value app for iPhone and iPad on the App Store.
        </p>
        <Links.AppBanner/>
        </Container>
      </Jumbotron>
      </section>

      

      <section id='testimonials' className='text-center my-5'>
        <Container>
          <h2>Testimonials</h2>
          <p className='mx-1'>
              App Store feedback from our customers
          </p>
          <CardDeck className="mx-auto">

            <TestimonialCard 
              text="Just purchased a second hand car and used autoClarity to run a full check. It was easy and swift to use and gave me the confidence to purchase it!
              I would absolutely recommend and would use again when purchasing any other vehicle."
              who="notluckyvoice"
              when="03/06/2019">
            </TestimonialCard>

            <TestimonialCard 
              text="So quick and simple, able to check specification of all vehicles I’m considering to purchase within seconds."
              who="Nicki Novice"
              when="07/09/2018">
            </TestimonialCard>

            <TestimonialCard 
              text="Clear and easy to follow. I found out my car is due an MOT this month! I had forgotten."
              who="Endseason"
              when="26/02/2018">
            </TestimonialCard>
          </CardDeck>
        </Container>
      </section>
             
      <section id='data-sources'>
      <Jumbotron fluid className='text-center'>
        <Container>
        <h2>Our Data Sources</h2>
        <p className='mx-5'>
          Our vehicle data is sourced from the industry's leading providers. These include Experian, the ABI, DVLA and VOSA so you can be sure
          you are getting the best data available at highly competitive prices.
        </p>
        <p>
          Try it out, the autoClarity: Car Check &amp; Value app for iPhone is available to download free from the App Store.
        </p>
        <Row>
          <Col xs={{ span: 1, offset: 4 }}>
            <img src={ExperianLogo} alt='Experian'/>
          </Col>
          <Col xs={{ span: 1, offset: 2}}>
            <img src={AbiLogo} alt='ABI'/>
          </Col>
        </Row>

        <Links.AppDownloadButton />
        </Container>
      </Jumbotron>
      </section>


    </>
)
};

export default Landing;