import firebase, { timestamp } from '../components/Firebase/firebase'

const getPublicStripeKey = async options => {
    var stripeKey = firebase.functions().httpsCallable('stripePublicKey')

    var result = await stripeKey().catch(error => {
        throw Error('API error')
    })
    console.log('got key ' + result.data.publicKey)
    return result.data.publicKey
};

const createPaymentIntent = async options => {

    var intent = firebase.functions().httpsCallable('createPaymentIntent')
    var result = await intent(options).catch(error => {
        console.log('API error:', error.message)
        throw Error('API error')
    })
    return result.data
}

const createPurchaseOrder = async (user, product) => {
    //transform the product to a purchase order object
    const prodKey = product.productKey 
    const prod = product.product
    const po = {
        productKey: prodKey,
        amount: prod.webPrice,
        description: prod.description,
        created: timestamp()
    }
    const poRef = firebase.database().ref('purchase-orders').child(user.uid).push()
    await poRef.set(po)
    return poRef.key
}

/**
 * 
 * @param {object} stripe the initialised stripe object that get passed through props 
 * @param {object} user the current user
 * @param {string} email the user's email to send a receipt
 * @param {object} product the product being purchased
 */
const createPayment = async(stripe, user, email, product) => {

    const orderId = await createPurchaseOrder(user, product)
    const intent = await createPaymentIntent({payment_method_types: ['card'], order_id: orderId, receipt_email: email})
    return await stripe.handleCardPayment(intent.client_secret)
}

const PaymentsAPI = {
    getPublicStripeKey,
    createPayment
}

export default PaymentsAPI


  