import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'
import 'firebase/functions'


import * as ROUTES from '../../constants/routes.js'

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID
  }

 // Configure FirebaseUI.
export const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: ROUTES.ACCOUNT,
    // We will display Phone and Facebook as auth providers.
    signInOptions: [ 
        firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        {
           provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
           defaultCountry: 'GB',
           whitelistedCountries: ['GB']
        },
        {
            provider: "apple.com"
        }
    ]
} 

export const timestamp = () => {
    return firebase.database.ServerValue.TIMESTAMP
}

export default !firebase.apps.length ? firebase.initializeApp(config) : firebase.app()