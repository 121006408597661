import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Table from 'react-bootstrap/Table';

const Yes = () => (
     <td><FontAwesomeIcon icon="check" color="green"/></td>
) 

const No = () => (
    <td><FontAwesomeIcon icon="times" color="red"/></td>
)

const CompareChecks = () => (
    <div>
        <h2 className='mt-5 mb-2'>Compare Vehicle Checks</h2>
        <p className='text-muted'>
            See below a comparison of our different check types.
    </p>
        <Table responsive>
            <thead>
                <tr>
                    <th>Check Features</th>
                    <th>Free Check</th>
                    <th>Full Check</th>
                    <th>Basic Check</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>£40,000 Guarantee</td>
                    <No />
                    <Yes />
                    <No />
                </tr>
                <tr>
                    <td>Outstanding Finance</td>
                    <No />
                    <Yes />
                    <No />
                </tr>
                <tr>
                    <td>3rd Party High Risk</td>
                    <No />
                    <Yes />
                    <No />
                </tr>
                <tr>
                    <td>Valuation Credit</td>
                    <No />
                    <Yes />
                    <No />
                </tr>
                <tr>
                    <td>Written Off</td>
                    <No />
                    <Yes />
                    <Yes />
                </tr>
                <tr>
                    <td>Stolen</td>
                    <No />
                    <Yes />
                    <Yes />
                </tr>
                <tr>
                    <td>Imported / Exported</td>
                    <No />
                    <Yes />
                    <Yes />
                </tr>
                <tr>
                    <td>Scrapped</td>
                    <Yes />
                    <Yes />
                    <Yes />
                </tr>
                <tr>
                    <td>ULEZ Exemption</td>
                    <Yes />
                    <Yes />
                    <Yes />
                </tr>
                <tr>
                    <td>MOT History Reports</td>
                    <Yes />
                    <Yes />
                    <Yes />
                </tr>
                <tr>
                    <td>Keeper Changes</td>
                    <Yes />
                    <Yes />
                    <Yes />
                </tr>
                <tr>
                    <td>Plate Changes</td>
                    <Yes />
                    <Yes />
                    <Yes />
                </tr>
                <tr>
                    <td>Tax Costs</td>
                    <Yes />
                    <Yes />
                    <Yes />
                </tr>
                <tr>
                    <td>MOT / Tax Due</td>
                    <Yes />
                    <Yes />
                    <Yes />
                </tr>
                <tr>
                    <td>VIN Check</td>
                    <Yes />
                    <Yes />
                    <Yes />
                </tr>
                <tr>
                    <td>Fuel Economy</td>
                    <Yes />
                    <Yes />
                    <Yes />
                </tr>
                <tr>
                    <td>Performance</td>
                    <Yes />
                    <Yes />
                    <Yes />
                </tr>
                <tr>
                    <td>30+ More Check Items</td>
                    <Yes />
                    <Yes />
                    <Yes />
                </tr>


            </tbody>
        </Table>
    </div>


);

export default CompareChecks;
