import { useState, useEffect } from 'react'
import firebase from '../Firebase/firebase'


export const useFetchAllProducts = () => {

    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [fullCheckProducts, setFullCheckProducts] = useState(null)
    const [basicCheckProducts, setBasicCheckProducts] = useState(null)
    const [valueVehicleProducts, setValueVehicleProducts] = useState(null)
    const [describeVehicleProducts, setDescribeVehicleProducts] = useState(null)

    useEffect(
        () => {
                firebase
                        .database()
                        .ref('app')
                        .child('products')
                        .once('value', snapshot => {
                            setLoading(false)
                            if (snapshot && snapshot.exists()) {
                                var fullChecks = []
                                var basicChecks = []
                                var vehicleDescs = []
                                var valuations = []
                                snapshot.forEach(product => {
                                    if (product.val().productTypeKey === 'full-checks') {
                                        fullChecks.push({productKey: product.key, product: product.val()})
                                    } else if (product.val().productTypeKey === 'basic-checks') {
                                        basicChecks.push({productKey: product.key, product: product.val()})
                                    } else if (product.val().productTypeKey === 'vehicle-descriptions') {
                                        vehicleDescs.push({productKey: product.key, product: product.val()})
                                    } else if (product.val().productTypeKey === 'vehicle-valuations') {
                                        valuations.push({productKey: product.key, product: product.val()})
                                    }
                                })
                                setFullCheckProducts(fullChecks)
                                setBasicCheckProducts(basicChecks)
                                setDescribeVehicleProducts(vehicleDescs)
                                setValueVehicleProducts(valuations) 
                            } else {
                                setFullCheckProducts(null)
                                setBasicCheckProducts(null)
                                setValueVehicleProducts(null)
                                setDescribeVehicleProducts(null)
                            }
                        }, error => {
                            setError(error)
                        })
                return
            }  
        , []
    )

    return {
        error,
        loading,
        fullCheckProducts,
        basicCheckProducts,
        valueVehicleProducts,
        describeVehicleProducts
    }
}
