import React, { useState, useEffect } from 'react'
import { Elements, StripeProvider } from 'react-stripe-elements'
import CheckoutForm from './checkoutForm'
import paymentsAPI from '../../utils/paymentsAPI'
import { useAuth } from '../Hooks/useAuth'
import { useFetchProduct } from '../Hooks/useFetchProduct'
import { PageSpinner } from '../Shared/Page/pageSpinner'




const Checkout = (props) => {

    const auth = useAuth()
    const { error, loading, product } = useFetchProduct(props.productKey)
    const [stripeKey, setStripeKey] = useState(null)

    useEffect(
        () => {
            if (!stripeKey) {
                paymentsAPI.getPublicStripeKey().then( key => {
                    setStripeKey(key)
                })
            }
        },[stripeKey]  
    )

    if (loading || !stripeKey) {
        return <PageSpinner/>
    }

    return (
            <div className='checkout'>

                {error && 
                    <p className='text-danger'>Error loading product. Try refreshing the page.</p>
                }
                {!loading && auth && stripeKey && product &&
                    <StripeProvider apiKey={stripeKey}>
                        <Elements>
                            <CheckoutForm product={product} user={auth.user}/>
                        </Elements>
                    </StripeProvider>
                }
            </div>
    )
}
export default Checkout