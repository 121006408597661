import React, { createRef, useEffect, useState } from 'react';
import {Helmet} from 'react-helmet';
import Button from 'react-bootstrap/Button';
import CompareChecks from './compareChecks';
import PageTitle from '../Shared/Page/pageTitle';
import Links from '../Shared/ButtonLinks';
import { ProductCardDeck } from '../Products/productCard'
import { useFetchAllProducts } from '../Hooks/useFetchAllProducts'
import * as ROUTES from '../../constants/routes'
import { PageSpinner } from '../Shared/Page/pageSpinner'
import { Redirect, Link } from 'react-router-dom'


const Products = (props) => {

    const {error: productsError, loading: productsLoading, fullCheckProducts, basicCheckProducts, valueVehicleProducts, describeVehicleProducts} = useFetchAllProducts()
    const [product, setProduct] = useState(null)
    const checkCompareRef = createRef();
    const vehicleDescRef = createRef();


    const handleClick = () =>
        checkCompareRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });

    useEffect(() => {
        if (props.location.hash.includes('checkCompare')) {
            handleClick();
        } else if (props.location.hash.includes('vehicleDescriptions')) {
            vehicleDescRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    })

    const onClickBuyHandler = (productKey) => {
        setProduct({productKey: productKey})
    }

    if (product) {
        return <Redirect push to={{
            pathname:ROUTES.CHECKOUT,
            state:{
                productKey:product.productKey
             }
           }} />
    }

   return (
        <>
        <Helmet>
            <title>Car Check and Valuation Products and Prices</title>
            <meta name="description" content="autoClarity: Car Check &amp; Value app vehicle check and valuation products and prices" />
            <link rel="canonical" href="https://autoclarity.co.uk/products" />
            <meta property="og:title" content="Car Check and Valuation Products and Prices"/>
            <script type="application/ld+json">
            {
                `{
                    "@context" : "http://schema.org",
                    "@type" : "Product",
                    "aggregateRating": {
                        "@type": "AggregateRating",
                        "ratingValue": "4.7",
                        "reviewCount": "377"
                      },
                    "name" : "Full Check",
                    "description" : "Our Full Check service provides a comprehensive, guaranteed report on the history of the vehicle showing full details of any outstanding finance, 3rd party risk alerts, insurance write-offs, stolen reports, mileage anomolies and more at great prices. Our data is sourced from the industries leading providers including Experian, DVLA, VOSA, and the ABI.",
                    "url" : "https://autoclarity.co.uk/products",
                    "image" : "https://autoclarity.co.uk/static/media/AC_Check.7a09c628.png",
                    "sku": "FullCheckBundle_sml",
                    "brand": {
                        "@type": "Thing",
                        "name": "autoClarity"
                      },
                    "review": {
                        "@type": "Review",
                        "reviewRating": {
                            "@type": "Rating",
                            "ratingValue": "5"
                        },
                        "author": {
                            "@type": "Person",
                            "name": "notluckyvoice"
                        },
                        "reviewBody": "Just purchased a second hand car and used autoClarity to run a full check. It was easy and swift to use and gave me the confidence to purchase it! I would absolutely recommend and would use again when purchasing any other vehicle."
                    },
                    "offers" : {
                        "@type" : "Offer",
                        "availability": "http://schema.org/InStock",
                        "price" : "8.99",
                        "priceCurrency" : "GBP",
                        "url" : "https://autoclarity.co.uk/products",
                        "sku": "FullCheckBundle_sml"
                    }
                }`
            }
            </script>
        </Helmet>
        <PageTitle title='Products' 
                    lead="Our vehicle checks and valuations are available to purchase on the website or in the app. Our best deals are on the website."
        />
        <div className='container'>
            {/* Full Check products section */}
            <h2 className='mt-5 mb-2'>Full Check Bundles</h2>
            <p>
                Our Full Check service provides a comprehensive, <Link to={{pathname: ROUTES.VEHICLE_CHECK_GUARANTEE}}>guaranteed</Link> report on the history of the vehicle
                showing full details of any outstanding finance, 3rd party risk alerts, insurance write-offs, stolen reports, mileage anomolies and
                more at great prices. 
                <Button variant='link' onClick={handleClick}>
                Compare Check Types
                </Button>
            </p>
            
            {productsLoading &&
                <PageSpinner/>
            }

            {productsError &&
                <p className='text-danger'>Error loading products, try refreshing the page.</p>
            }

            {!productsLoading && fullCheckProducts && 
                <ProductCardDeck products={fullCheckProducts} onClickHandler={onClickBuyHandler}/>
            }  

            <div className='text-center'><Links.SampleReportButton /></div>

            {/* Basic Check products section */}
            <h2 className='mt-5 mb-2'>Basic Check</h2>
            <span className='align-middle'>
                Our Basic Check will report whether the vehicle has any history of being stolen, written off by an insurer, or scrapped. 
                <Button variant='link' onClick={handleClick}>
                    Compare Check Types
                </Button>
            </span>
            {productsLoading &&
                <PageSpinner/>
            }
            {!productsLoading && basicCheckProducts && 
                <ProductCardDeck products={basicCheckProducts} onClickHandler={onClickBuyHandler}/>
            }  

            {/* Valuation products section */}
            <h2 className='mt-5 mb-2'>Valuation Bundles</h2>
            <p>autoClarity: Car Check &amp; Value app valuations are based on actual market data and inform you of trade, auction and private sale prices</p>
            {productsLoading &&
                <PageSpinner/>
            }
            {!productsLoading && valueVehicleProducts && 
                <ProductCardDeck products={valueVehicleProducts} onClickHandler={onClickBuyHandler}/>
            }  

            {/* Vehicle Description products section */}
            <h2 className='mt-5 mb-2' ref={vehicleDescRef}>Vehicle Descriptions</h2>
            <p className='text-muted'>autoClarity: Car Check &amp; Value app gives you free<sup>**</sup> vehicle lookups which are ideal for irregular users wanting to query a vehicle for common requirements
            like finding out when tax is due, or for what reason a vehicle failed an MOT. If you're looking up lots of cars and use up your free credits,
            you can buy top-ups. </p>
            {productsLoading &&
                <PageSpinner/>
            }
            {!productsLoading && describeVehicleProducts && 
                <ProductCardDeck products={describeVehicleProducts} onClickHandler={onClickBuyHandler}/>
            }  
            <p className='text-muted small'>
                <sup>**</sup> A vehicle description is valid for the day it was looked up. 
                This means you can lookup the same vehicle as often as you wish on the same day and consume only one free or paid for credit. 
                autoClarity: Car Check &amp; Value app comes with a number of free vehicle lookups and we support the cost of these by displaying ads. autoClarity: Car Check &amp; Value app does not display ads for accounts that are in credit. Your free vehicle lookup quota resets periodically.
            </p>

            <div id="checkCompare" ref={checkCompareRef}>
                <CompareChecks/>
            </div>
        </div>
        </>

    )

}

export default Products;