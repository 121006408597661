import React from 'react'
import { Container, Col, Row, CardGroup } from 'react-bootstrap'
import Logo from '../../assets/autoclarity_image.svg'
import { useParams, Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Card from 'react-bootstrap/Card'
import ListGroup from 'react-bootstrap/ListGroup'
import Table from 'react-bootstrap/Table'
import { useFetchListingWithSupplementary } from '../Hooks/useFetchListingWithSupplementary'
import { faCarCrash, faCheckCircle, faMoneyBillAlt, faShieldAlt, faExclamationTriangle, faTools,
     faLongArrowAltLeft, faLongArrowAltRight, faUsers, faFileInvoice, faExclamationCircle, faEye, faTachometerAlt} from '@fortawesome/free-solid-svg-icons'
import { LineChart, XAxis, CartesianGrid, Line, YAxis } from 'recharts'
import * as ROUTES from '../../constants/routes'
import * as dateFormatters from '../../utils/dateFormatter'

const colIconColor = "#404C60"
const colCheckClearColor = "#33cc33"
const colCheckWarningColor = "#ff9933"
const colCheckAlertColor = "#ff0000"
const bgAlertColor = "#ffe9ea"
const fullCheck = "Full"
const basicCheck = "Basic"


const NotCheckedReportCol = (colIcon, colString) => {
    return <Row className="py-4 border">
                <Col>
                    <FontAwesomeIcon icon={colIcon} color={colIconColor} className="mr-4"/>{colString}
                    <span className="pull-right">
                        <FontAwesomeIcon icon={faExclamationTriangle} color={colIconColor}/>
                    </span>
                </Col>
           </Row>
}

const CheckedReportCol = (boolExpr, trueString, falseString, colIcon, trueIcon, trueIconColor) => {
    if (boolExpr === true) {
        return <Row className="py-4 border">
                    <Col>
                        <FontAwesomeIcon icon={colIcon} color={colIconColor} className="mr-4"/>{trueString}
                        <span className="pull-right">
                            <FontAwesomeIcon icon={trueIcon} color={trueIconColor}/>
                        </span>
                    </Col>
                </Row>
    } 
    return <Row className="py-4 border">
                <Col>
                    <FontAwesomeIcon icon={colIcon} color={colIconColor} className="mr-4"/>{falseString}
                    <span className="pull-right">
                        <FontAwesomeIcon icon={faCheckCircle} color={colCheckClearColor}/>
                    </span>
                </Col>
            </Row>
}

const ReportSummary = (props) => {
    return (
        <div className="my-3 mx-3">
            <h4>Report Summary</h4>
            <div className="pb-3">
                <Row>
                    <Col xs={12} sm={6}>
                        {CheckedReportCol(props.stolen, 'STOLEN', 'NOT STOLEN', faShieldAlt, faExclamationTriangle, colCheckAlertColor)}
                        {CheckedReportCol(props.scrapped, 'SCRAPPED', 'NOT SCRAPPED', faTools, faExclamationTriangle, colCheckAlertColor)}
                        {CheckedReportCol(props.exported, 'EXPORTED', 'NOT EXPORTED', faLongArrowAltLeft, faExclamationTriangle, colCheckAlertColor)}
                        {CheckedReportCol(props.imported, 'IMPORTED', 'NOT IMPORTED', faLongArrowAltRight, faExclamationCircle, colCheckWarningColor)}
                        {CheckedReportCol(props.writtenOff, 'WRITTEN OFF', 'NOT WRITTEN OFF', faCarCrash, faExclamationTriangle, colCheckAlertColor)}
                    </Col>

                    <Col xs={12} sm={6}>
                        {(props.checkType === basicCheck && NotCheckedReportCol(faFileInvoice, 'FINANCE NOT CHECKED') )
                        || (props.checkType === fullCheck && CheckedReportCol(props.finance > 0, 'OUTSTANDING FINANCE', 'NO OUTSTANDING FINANCE', faFileInvoice, faExclamationCircle, colCheckWarningColor))}
                            
                        {(props.checkType === basicCheck && NotCheckedReportCol(faEye, 'HIGH RISK ALERT NOT CHECKED') )
                        || (props.checkType === fullCheck && CheckedReportCol(props.highRisk > 0, 'HIGH RISK ALERTS', 'NO HIGH RISK ALERTS', faEye, faExclamationCircle, colCheckWarningColor))} 
                        
                        {(props.checkType === basicCheck && NotCheckedReportCol(faTachometerAlt, 'NATIONAL MILEAGE REGISTER NOT CHECKED'))
                        || (props.checkType === fullCheck && CheckedReportCol(props.mileageAnomaly, 'MILEAGE ANOMALY', 'NO MILEAGE DISCREPANCIES', faTachometerAlt, faExclamationCircle, colCheckWarningColor))} 

                        {CheckedReportCol(props.plateChanges > 0, `${props.plateChanges} PLATE CHANGE(S)`, 'NO PLATE CHANGES', faMoneyBillAlt, faExclamationCircle, colCheckWarningColor)}
                        {CheckedReportCol(props.previousKeepers > 0, `${props.previousKeepers} PREVIOUS OWNER(S)`, 'NO PREVIOUS OWNERS', faUsers, faCheckCircle, colCheckClearColor)}
                    </Col>
                </Row> 
            </div>
        </div>
    )
}

const CheckSummaryCard = (checkTimestamp, vrm, make, model, year, checkRef, checkOwnerUID) => {
    const checkDate = dateFormatters.formattedDateTime(checkTimestamp)
    const last8UID = checkOwnerUID.slice(checkOwnerUID.length - 8)
    return (
        <Card >
            <Card.Body>
                <Table responsive="sm" borderless={true}>
                    <tbody>
                        <tr>
                            <th>VRM</th>
                            <td>{vrm}</td>
                        </tr>
                        <tr>
                            <th>Vehicle</th>
                            <td>{make} {model}</td>
                        </tr>
                        <tr>
                            <th>Manuf. Year</th>
                             <td>{year}</td>
                        </tr>
                        <tr>
                            <th>Checked On</th>
                            <td>{checkDate}</td>
                        </tr>
                        <tr>
                            <th>Check Ref</th>
                            <td>{checkRef}</td>
                        </tr>
                        <tr>
                            <th>autoClarity ID</th>
                            <td>{last8UID}</td>
                        </tr>
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    ) 
}

const AlertListItem = (itemText) => {
    return <ListGroup.Item><FontAwesomeIcon icon={faExclamationTriangle} color={colCheckAlertColor}/> {itemText}</ListGroup.Item>
}

const AlertSummaryCard = (stolen, writtenOff, scrapped, exported, outstandingFinance, highRisk, mileageAnomaly) => {
    return (
        (stolen === true || writtenOff === true || scrapped === true || exported === true || outstandingFinance === true || highRisk === true || mileageAnomaly === true) &&
        <Card style={{backgroundColor:bgAlertColor}}>
            <Card.Header><h4>Vehicle Alerts</h4></Card.Header>
            <Card.Body>
                <ListGroup variant='flush'>
                {stolen === true && AlertListItem('This vehicle registration is reported as stolen')}   
                {writtenOff === true && AlertListItem('This vehicle registration is reported as written off')}  
                {scrapped === true && AlertListItem('This vehicle registration is reported as scrapped')}  
                {exported === true && AlertListItem('This vehicle registration is reported as exported')}  
                {outstandingFinance === true && AlertListItem('This vehicle registration is reported as having outstanding finance')}
                {highRisk === true && AlertListItem('This vehicle registration is reported as having high risk markers')} 
                {mileageAnomaly === true && AlertListItem('This vehicle registration is reported as having a mileage discrepancy')}    
                </ListGroup>
            </Card.Body>   
        </Card>
    )
}

const CheckSummary = (props) => {
    return (
        <div>
            <img className='pull-right' style={{backgroundColor:'darkgray', marginTop:'3px' ,paddingLeft:'5px', paddingRight:'5px'}} src={Logo} alt="autoclarity" width="175" height="38"/>
            <h1>{props.checkType} Vehicle Check Report</h1>
            <p className='text-muted'>Report Generated on {Date().toLocaleString()}</p>
            <Row >
                <Col className="my-2" md={6}>{CheckSummaryCard(props.checkTimestamp, props.vrm, props.make, props.model, props.year, props.checkRef, props.owner)}</Col>
                <Col className="my-2" md={6}>{AlertSummaryCard(props.stolen, props.writtenOff, props.scrapped, props.exported, props.outstandingFinance, props.highRisk, props.mileageAnomaly)}</Col>
            </Row>
        </div>
    )
}

const MessagesSection = (props) => {
    const advisories = props.statusCode !== 'Success' && props.adviseTextList.map((advise, idx) => {
        return <tr key={idx}><td>{advise}</td></tr>
    })
    return (
        props.statusCode !== 'Success' &&
         <div class="py-3">
            <h4>Messages</h4>
            <Table striped bordered>
                <tbody>
                    {advisories}
                </tbody>
            </Table>
        </div>
    )
}

const VehicleDescriptionSection = (props) => {
    const dateFirstRegd = dateFormatters.formattedDate(props.dateFirstRegd)
    return (
        <div className="py-3">
            <h4>Vehicle Description</h4>
            <p>
                Check the vehicle description and appearance matches the details in the V5 logbook. If not, you should query the discrepancy with the seller and the DVLA. Contact 
                the DVLA on 0300 790 6802. Only the registered keeper of the vehicle can request the DVLA to make any changes to vehicle data.
            </p>
            <Table striped bordered>
                <tbody>
                <tr>
                    <th>Make</th>
                    <td>{props.make}</td>
                </tr>
                <tr>
                    <th>Model</th>
                    <td>{props.model}</td>
                </tr>
                <tr>
                    <th>Manufacture Year</th>
                    <td>{props.year}</td>
                </tr>
                <tr>
                    <th>Date First Regd.</th>
                    <td>{dateFirstRegd}</td>
                </tr>
                <tr>
                    <th>Colour</th>
                    <td>{props.colour}</td>
                </tr>
                <tr>
                    <th>Engine Number</th>
                    <td>{props.engineNumber}</td>
                </tr>
                <tr>
                    <th>Engine Size cc</th>
                    <td>{props.engineSize}</td>
                </tr>
                <tr>
                    <th>Fuel</th>
                    <td>{props.fuel}</td>
                </tr>
                <tr>
                    <th>Body Style</th>
                    <td>{props.bodyStyle}</td>
                </tr>
                <tr>
                    <th>Transmission</th>
                    <td>{props.transmission}</td>
                </tr>
                <tr>
                    <th>Co2 Emissions</th>
                    <td>{props.co2Emissions}</td>
                </tr>
                <tr>
                    <th>Scrapped?</th>
                    <td>{props.scrapped}</td>
                </tr>
                <tr>
                    <th>Exported?</th>
                    <td>{props.exported}</td>
                </tr>
                <tr>
                    <th>Imported?</th>
                    <td>{props.imported}</td>
                </tr>
                </tbody>
            </Table>
        </div>
    )
}

const VehicleTaxMotStatus = (props) => {
    let motDue = dateFormatters.formattedDate(props.motDueTimestamp)
    let taxDue = dateFormatters.formattedDate(props.taxDueTimestamp)
    let motDueStatus
    let taxDueStatus

    if (props.motDueTimestamp) {
        motDueStatus = props.motDueTimestamp >= Date.now() ? 'MOT is valid' : 'MOT due date has passed'
    } else {
        motDueStatus = 'Not available'
    }

    if (props.taxDueTimestamp) {
        taxDueStatus = props.taxDueTimestamp >= Date.now() ? 'Tax is valid' : 'Tax due date has passed'
    } else {
        taxDueStatus = 'Not available'
    }

    let sornStatus = props.sorn === true ? 'Active SORN' : "Not SORN"

    return (
        <div className="py-3">
            <h4>Tax and MOT Status</h4>
            <p>Details below show due dates for Tax and MOT and whether the vehicle is registered
                 with the DVLA as SORN (Statutory Off Road Notification) </p>
            <Table striped bordered>
            <tbody>
                <tr>
                    <th>MOT Due</th>
                    <td>{motDue} </td>
                </tr>
                <tr>
                    <th>MOT Status</th>
                    <td>{motDueStatus}</td>
                </tr>
                <tr>
                    <th>Tax Due</th>
                    <td>{taxDue}</td>
                </tr>
                <tr>
                    <th>Tax Status</th>
                    <td>{taxDueStatus}</td>
                </tr>
                <tr>
                    <th>SORN Status</th>
                    <td>{sornStatus}</td>
                </tr>
            </tbody>
            </Table>
        </div>
    )
}

const ConditionReport = (key, lossTimestamp, miaftrTimestamp, category, lossType) => {
    const lossDate = dateFormatters.formattedDate(lossTimestamp)
    const miaftrEntryDate = dateFormatters.formattedDate(miaftrTimestamp)
    return (
        <Card key={key} style={{backgroundColor:bgAlertColor}}>
            <Card.Body>
            <Table>
                <tbody>
                    <tr>
                        <th>Loss Type</th>
                        <td>{lossType}</td>
                    </tr>
                    <tr>
                        <th>Category</th>
                        <td>{category}</td>
                    </tr>
                    <tr>
                        <th>Loss Date</th>
                        <td>{lossDate}</td>
                    </tr>
                    <tr>
                        <th>MIAFTR Entry Date</th>
                        <td>{miaftrEntryDate}</td>
                    </tr>
                </tbody>
            </Table>
            </Card.Body>
        </Card>
    )
}

const ConditionReportsSection = (props) => {
    const conditions = props.conditionCount > 0 && props.conditionItems.map((condition, idx) => {
        return ConditionReport(idx, condition.lossDate, condition.MIAFTRENTRYDATE, condition.category, condition.lossType)
    }) 
    return (
        <div className="py-3">
            <h4>Vehicle Condition</h4>
            <p>Details below will show if the registration has been written off or recorded stolen by insurers</p>
            {props.writtenOff === true && 
                <p>This vehicle is recorded as an insurance write off - {props.writeOffCategory}</p> 
            }
            {props.conditionCount > 0 &&
                <CardGroup>
                {conditions}
                </CardGroup>
            }
            {props.conditionCount === 0 && 
                <p>No condition records to report</p>
            }    
        </div>
    )  
}

const StolenVehiclePoliceSection = (props) => {
    let stolenDate = dateFormatters.formattedDate(props.stolenDate)
    return (
        <div className="py-3">
            <h4>Stolen Vehicle Police Record</h4>
            <p>Details below will show if this registration is reported stolen on the Police National Computer</p>
            {props.stolen === true ?
            <Card style={{backgroundColor:bgAlertColor}}>
                <Card.Body>
                <Table>
                    <tbody>
                        <tr>
                            <th>Source</th>
                            <td>{props.source}</td>
                        </tr>
                        <tr>
                            <th>Stolen Date</th>
                            <td>{stolenDate}</td>
                        </tr>
                        <tr>
                            <th>Police Force</th>
                            <td>{props.policeForce}</td>
                        </tr>
                        <tr>
                            <th>Contact Number</th>
                            <td>{props.contactNumber}</td>
                        </tr>
                    </tbody>
                </Table>
                </Card.Body>
                <Card.Footer>
                    <p>Additional information may be shown in the Vehicle Condition section</p>
                </Card.Footer>
            </Card>
            : <p>Vehicle not recorded as stolen</p>
        }
        </div>
    )
}

const OutstandingFinanceReport = (key, agreementDate, agreementNumber, agreementTerm, agreementType, contactNumber, financeCompany, vehicleDescription) => {
    const agreementDateStr = dateFormatters.formattedDate(agreementDate)
    return (
        <Card key={key} style={{backgroundColor:bgAlertColor}}>
            <Card.Body>
            <Table>
                <tbody>
                    <tr>
                        <th>Agreement Date</th>
                        <td>{agreementDateStr}</td>
                    </tr>
                    <tr>
                        <th>Agreement Number</th>
                        <td>{agreementNumber}</td>
                    </tr>
                    <tr>
                        <th>Agreement Term</th>
                        <td>{agreementTerm}</td>
                    </tr>
                    <tr>
                        <th>Agreement Type</th>
                        <td>{agreementType}</td>
                    </tr>
                    <tr>
                        <th>Contact Number</th>
                        <td>{contactNumber}</td>
                    </tr>
                    <tr>
                        <th>Finance Company</th>
                        <td>{financeCompany}</td>
                    </tr>
                    <tr>
                        <th>Vehicle Description</th>
                        <td>{vehicleDescription}</td>
                    </tr>
                </tbody>
            </Table>
            </Card.Body>
            <Card.Footer>
            The finance company owns the vehicle until the loan is fully paid. If you buy a vehicle with outstanding 
            finance the finance company may reclaim the vehicle.
            </Card.Footer>
        </Card>
    )    
}

const OutstandingFinanceSection = (props) => {
    const financeItems = props.financeItemsCount > 0 && props.financeItems.map((finance, idx) => {
        return OutstandingFinanceReport(idx, finance.agreementDate, finance.agreementNumber, finance.agreementTerm, finance.agreementType, finance.contactNumber, finance.financeCompany, finance.vehicleDescription)
    }) 
    return (
        <div className="py-3">
            <h4>Outstanding Finance</h4>
            <p>Details below will show details of any outstanding finance agreements on this registration</p>
            {props.financeItemsCount > 0 &&
                <>
                <p>We recommend that you use the details provided to contact the finance company and 
                    determine the current status of the finance secured against the registration plate.
                </p>
                <CardGroup>
                {financeItems}
                </CardGroup>
                </>
            }
            {props.financeItemsCount === 0 && 
                <p>No outstanding finance records on the Experian register</p>
            }    
        </div>
    )  
}

const KeeperChange = (key, lastChangeDate, noPreviousKeepers, transactionDate) => {
    const lastChange = dateFormatters.formattedDate(lastChangeDate)
    const txDate = dateFormatters.formattedDate(transactionDate)
    return (
        <Card border="info" key={key}>
            <Card.Body>
            <Table>
                <tbody>
                    <tr>
                        <th>Previous Keepers</th>
                        <td>{noPreviousKeepers}</td>
                    </tr>
                    <tr>
                        <th>Last Change Date</th>
                        <td>{lastChange}</td>
                    </tr>
                    <tr>
                        <th>Transaction Date</th>
                        <td>{txDate}</td>
                    </tr>
                </tbody>
            </Table>
            </Card.Body>
        </Card>
    )    
}

const KeeperChangeSection = (props) => {
    const keeperItems = props.keeperItemsCount > 0 && props.keeperItems.map((keeper, idx) => {
        return KeeperChange(idx, keeper.lastChangeDate, keeper.noPreviousKeepers, keeper.txDate)
    })
    return (
        <div className="py-3">
            <h4>Previous Keepers</h4>
            <p>Details below will show any previous keepers of this registration</p>
            {props.keeperItemsCount > 0 &&
                <CardGroup>
                {keeperItems}
                </CardGroup>
            }
            {props.keeperItemsCount === 0 && 
                <p>No previous keeper records to report</p>
            }    
        </div>
    )  
}

const VehicleIDSection = (props) => (
<div className="py-3">
            <h4>Vehicle Identification</h4>
            <p>The last 5 characters of the VIN are shown. Perform a full VIN check using the autoClarity app.</p>
            {props.vinLast5 &&
                <Table striped bordered>
                <tbody>
                    <tr><th>VIN Last 5</th><td>{props.vinLast5}</td></tr>
                </tbody>
                </Table>
            }
            {props.regCertCount === 0 &&
                <p>No V5 Certificates recorded</p>
            }
        </div>
)

const V5Certificate = (certificateDate, key) => {
    let certificateDateStr = dateFormatters.formattedDate(certificateDate)
    return (
        <tr key={key}>
            <th>Certificate Date</th>
            <td>{certificateDateStr}</td>
        </tr> 
    )
}

const V5CertificatesSection = (props) => {
    const certRows = props.regCertCount > 0 && props.regCertItems.map((regCert, idx) => {
        return V5Certificate(regCert.certificateDate, idx)
    })
    return (
        <div className="py-3">
            <h4>V5 (Vehicle Log Book) Certificates Issued</h4>
            <p>Details below will show the certificate issue dates</p>
            {props.regCertCount > 0 &&
                <Table striped bordered>
                <tbody>
                    {certRows}
                </tbody>
                </Table>
            }
            {props.regCertCount === 0 &&
                <p>No V5 Certificates recorded</p>
            }
        </div>
    )
}

const PlateChangeItem = (key, currentVRM, dateOfReceipt, previousVRM, transferType, transactionDate) => {
    const dateOfReceiptStr = dateFormatters.formattedDate(dateOfReceipt)
    const txDate = dateFormatters.formattedDate(transactionDate)
    return (
        <Card border="info" key={key}>
            <Card.Body>
            <Table>
                <tbody>
                    <tr>
                        <th>Current VRM</th>
                        <td>{currentVRM}</td>
                    </tr>
                    <tr>
                        <th>Previous VRM</th>
                        <td>{previousVRM}</td>
                    </tr>
                    <tr>
                        <th>Transfer Type</th>
                        <td>{transferType}</td>
                    </tr>
                    <tr>
                        <th>Receipt Date</th>
                        <td>{dateOfReceiptStr}</td>
                    </tr>
                    <tr>
                        <th>Transaction Date</th>
                        <td>{txDate}</td>
                    </tr>
                </tbody>
            </Table>
            </Card.Body>
        </Card>
    )    
}

const PlateChangesSection = (props) => {
    const plateChanges = props.plateChangeCount > 0 && props.plateChangeItems.map((plateChange, idx) => {
        return PlateChangeItem(idx, plateChange.currentVRM, plateChange.dateOfReceipt, plateChange.previousVRM, plateChange.transferType, plateChange.txDate)
    })
    return (
        <div className="py-3">
            <h4>Plate Changes</h4>
            <p>Details below will show any registration plate changes</p>
            {props.plateChangeCount > 0 &&
                <CardGroup>
                {plateChanges}
                </CardGroup>
            }
            {props.plateChangeCount === 0 && 
                <p>No plate change records to report</p>
            }    
        </div>
    )
}

//colour changes

const HighRiskItem = (key, dateOfInterest, registrationPeriod, highRiskType, contactNumber) => {
    let dateOfInterestStr = dateFormatters.formattedDate(dateOfInterest)
    return (
        <Card key={key} style={{backgroundColor:bgAlertColor}}>
            <Card.Body>
            <Table>
                <tbody>
                    <tr>
                        <th>Risk Type</th>
                        <td>{highRiskType}</td>
                    </tr>
                    <tr>
                        <th>Date of Interest</th>
                        <td>{dateOfInterestStr}</td>
                    </tr>
                    <tr>
                        <th>Registration Period</th>
                        <td>{registrationPeriod}</td>
                    </tr>
                    <tr>
                        <th>Contact Number</th>
                        <td>{contactNumber}</td>
                    </tr>
                </tbody>
            </Table>
            </Card.Body>
            <Card.Footer>
            High risk markers are placed on vehicles by interested parties. You are recommended to use the details above to
            contact the party and resolve any issues before purchasing this vehicle.
            </Card.Footer>
        </Card>
    )    
}

const HighRiskSection = (props) => {
    const riskItems = props.highRiskCount > 0 && props.highRiskItems.map((risk, idx) => {
        return HighRiskItem(idx, risk.dateOfInterest, risk.registrationPeriod, risk.highRiskType, risk.contactNumber)
    })
    return (
        <div className="py-3">
            <h4>High Risk Markers</h4>
            <p>Details below will show if any 3rd parties have created alerts for this registration</p>
            {props.highRiskCount > 0 &&
                <CardGroup>
                {riskItems}
                </CardGroup>
            }
            {props.highRiskCount === 0 && 
                <p>No high risk markers recorded</p>
            }    
        </div>
    )  
}

const MileageItems = (key, dateOfInformation, mileage, sourceOfMileage, subSourceOfMileage) => {
    let dateOfInformationStr = dateFormatters.formattedDate(dateOfInformation)
    return (
        <tr key={key}>
            <td>{dateOfInformationStr}</td>
            <td>{mileage}</td>
            <td>{sourceOfMileage}</td>
        </tr>
    )
}

const MileageSection = (props) => {
    const mileageItems = props.mileageItemsCount > 0 && props.mileageDataItems.map((mileageItem, idx) => {
        return MileageItems(idx, mileageItem.dateOfInformation, mileageItem.mileage, mileageItem.sourceOfMileage, mileageItem.subSourceOfMileage )
    })
    return (
        <div className="py-3">
            <h4>Vehicle Mileage</h4>
            <p>
                Mileage data helps to identify vehicles that may have had the mileage wound back, known as vehicle clocking. Odometer readings are taken
                during MOTs, services and keeper changes to maintain a mileage register.
            </p>
            {props.mileageAnomaly === true && 
                <p>This registration is showing a mileage discrepancy. We recommend that you query this with the seller.</p> 
            }
            {props.mileageItemsCount > 0 &&
            <Table striped bordered>
                <thead>
                    <tr>
                        <th>Information Date</th>
                        <th>Odometer reading</th>
                        <th>Source</th>
                    </tr>
                </thead>
                <tbody>
                    {mileageItems}
                </tbody>
            </Table>
            }
            {props.mileageItemsCount === 0 && 
                <p>No mileage data to report</p>
            }    
        </div>
    )
}

const MileageChart = (props) => {
    const mileageData = props.mileageItemsCount > 0 && props.mileageDataItems.reverse().map((mileageItem) => {
        let eventDateStr = dateFormatters.formattedDate(mileageItem.dateOfInformation)
        return (
            {
                eventDate: eventDateStr, 
                mileage: mileageItem.mileage
            }
        )
    })
   
    return (
        <LineChart
            width={900}
            height={400}
            data={mileageData}
            margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
        >
            <XAxis dataKey="eventDate" />
            <YAxis/>
            <CartesianGrid stroke="#f5f5f5" />
            <Line type="monotone" dataKey="mileage" stroke="#ff7300" yAxisId={0} />
        </LineChart>
    )
}

const ThankYouSection = (props) => {
    return (
    <div className="py-3">
        <h4>Thank you for using autoClarity: Car Check &amp; Value</h4>
        <small className='text-muted '>
            autoClarity and our data suppliers make every effort to ensure the information supplied is accurate. If you notice or are aware of any inaccuracies, please report them to us by emailing admin@autoclarity.co.uk. 
            By using our service you are acknowledging that you agree to our <Link to={ROUTES.GENERAL_TERMS}>Terms and Conditions</Link>.
        </small>
    </div>
    )
}

const CheckReport = () => {
    const { id } = useParams()
    const {listing, vehicleSupplementary} = useFetchListingWithSupplementary(id)

    let lookupEvents
    let checkTimestamp
    let checkType
    let vehicleRegistration
    let conditionReports
    let keeperChanges
    let stolenStatus
    let plateChanges
    let v5Certificates
    let mileage
    let financeHistory
    let highRisk
    let motVedStatus
    let vehicleExciseDuty
    let messageDetails


    // VehicleSupplementary is a container to organise child data
    // so pull out the children
    if (vehicleSupplementary) {
        lookupEvents = vehicleSupplementary.child('Lookup Events')
        checkTimestamp = lookupEvents.val().vehicleFullCheckTimestamp || lookupEvents.val().vehicleScreeningTimestamp
        checkType = lookupEvents.val().vehicleFullCheckTimestamp !== undefined ? fullCheck : basicCheck
        vehicleRegistration = vehicleSupplementary.child('Vehicle Registration')
        conditionReports = vehicleSupplementary.child('Condition Reports')
        keeperChanges = vehicleSupplementary.child('Keeper Changes')
        stolenStatus = vehicleSupplementary.child('Stolen')
        plateChanges = vehicleSupplementary.child('Plate Changes')
        v5Certificates = vehicleSupplementary.child('V5CCertificates')
        vehicleExciseDuty = vehicleSupplementary.child('Vehicle Excise Duty')

        motVedStatus = vehicleSupplementary.child('MOT & VED Status')
        messageDetails = vehicleSupplementary.child('Message Details')

        // FULL CHECK ONLY
        financeHistory = vehicleSupplementary.child('Finance History')
        highRisk = vehicleSupplementary.child('High Risk Record')
        mileage = vehicleSupplementary.child('Mileage')

    }
    

    return (
        listing && vehicleSupplementary && 
        <div>
        <Container className="my-5 bg-light shadow" style={{borderRadius: '15px'}}>
            <CheckSummary checkType={checkType}
                          checkTimestamp={checkTimestamp}
                          vrm={listing.val().vrm}
                          make={listing.val().make}
                          model={listing.val().model}
                          year={listing.val().yearOfManufacture}
                          checkRef={listing.key}
                          owner={listing.val().owner}
                          stolen={stolenStatus.val().stolen} 
                          scrapped={vehicleRegistration.val().scrapped} 
                          exported={vehicleRegistration.val().exported}
                          imported={vehicleRegistration.val().imported}
                          writtenOff={conditionReports.val().writtenOff}
                          mileageAnomaly={mileage?.val()?.mileageAnomaly}
                          finance={financeHistory?.val()?.financeRecordCount || 0}
                          highRisk={highRisk?.val()?.highRiskCount || 0}
            />
            <ReportSummary checkType={checkType}
                           stolen={stolenStatus.val().stolen} 
                           scrapped={vehicleRegistration.val().scrapped} 
                           exported={vehicleRegistration.val().exported}
                           imported={vehicleRegistration.val().imported}
                           writtenOff={conditionReports.val().writtenOff}
                           previousKeepers={keeperChanges?.val()?.changeCount || 0}
                           plateChanges={plateChanges?.val()?.plateChangeCount || 0}
                           mileageAnomaly={mileage?.val()?.mileageAnomaly}
                           finance={financeHistory?.val()?.financeRecordCount || 0}
                           highRisk={highRisk?.val()?.highRiskCount || 0}
            />
            <MessagesSection statusCode={messageDetails.val().statusCode}
                             adviseTextList={messageDetails.val().adviseTextList}
            />
        </Container>
        <Container className="my-5 bg-light shadow" style={{borderRadius: '15px'}}>
            <VehicleDescriptionSection make={listing.val().make}
                                        model={listing.val().model}
                                        colour={listing.val().colour}
                                        year={listing.val().yearOfManufacture}
                                        dateFirstRegd={listing.val().dateOfFirstRegistration}
                                        engineSize={listing.val().cylinderCapacity}
                                        engineNumber={vehicleRegistration.val().engineNumber}
                                        fuel={listing.val().fuelType}
                                        bodyStyle={listing.val().bodyStyle}
                                        transmission={listing.val().transType}
                                        co2Emissions={listing.val().co2Emissions}
                                        scrapped={vehicleRegistration.val().scrapped === true ? 'Yes' : 'Not recorded by the DVLA as scrapped'} 
                                        exported={vehicleRegistration.val().exported === true ? 'Yes' : 'Not recorded by the DVLA as exported'}
                                        imported={vehicleRegistration.val().imported === true ? 'Yes' : 'Not recorded by the DVLA as imported'}
            />
        </Container>
        <Container className="my-5 bg-light shadow" style={{borderRadius: '15px'}}>
            <VehicleTaxMotStatus motDueTimestamp={motVedStatus.val().motDue}
                                 taxDueTimestamp={motVedStatus.val().taxDue}
                                 sorn={vehicleExciseDuty.val().sorn}
            />
        </Container>
        <Container className="my-5 bg-light shadow" style={{borderRadius: '15px'}}>
            <ConditionReportsSection writtenOff={conditionReports.val().writtenOff}
                                     writeOffCategory={conditionReports.val().writeOffCategory}
                                     conditionCount={conditionReports.val().conditionDetailsItemsCount}
                                     conditionItems={conditionReports.child('condition-data-items').val()}   
            
            />
        </Container>
        <Container className="my-5 bg-light shadow" style={{borderRadius: '15px'}}>
            <StolenVehiclePoliceSection stolen={stolenStatus.val().stolen}
                                        source={stolenStatus.val()?.source}
                                        stolenDate={stolenStatus.val()?.stolenDate}
                                        policeForce={stolenStatus.val()?.policeForce}
                                        contactNumber={stolenStatus.val()?.contactNumber}
            />
        </Container>
        { checkType === fullCheck && <Container className="my-5 bg-light shadow" style={{borderRadius: '15px'}}>
            <OutstandingFinanceSection  financeItemsCount={financeHistory.val()?.financeRecordCount || 0} 
                                        financeItems={financeHistory.child('finance-data-items').val()}
            />
        </Container> }
        { checkType === fullCheck && <Container className="my-5 bg-light shadow" style={{borderRadius: '15px'}}>
            <HighRiskSection  highRiskCount={highRisk.val()?.highRiskCount || 0} 
                                        highRiskItems={highRisk.child('high-risk-items').val()}
            />
        </Container> }
        <Container className="my-5 bg-light shadow" style={{borderRadius: '15px'}}>
            <KeeperChangeSection  keeperItemsCount={keeperChanges.val()?.changeCount || 0} 
                                keeperItems={keeperChanges.child('keeper-change-items').val()}
            />
        </Container>
        <Container className="my-5 bg-light shadow" style={{borderRadius: '15px'}}>
            <VehicleIDSection  vinLast5={vehicleRegistration.val().vin}
            />
        </Container>
        <Container className="my-5 bg-light shadow" style={{borderRadius: '15px'}}>
            <V5CertificatesSection regCertCount={v5Certificates.val()?.regCertCount}
                                   regCertItems={v5Certificates.child('vehicle-reg-certs').val()}
            />
        </Container>
        <Container className="my-5 bg-light shadow" style={{borderRadius: '15px'}}>
            <PlateChangesSection  plateChangeCount={plateChanges.val()?.plateChangeCount || 0} 
                                plateChangeItems={plateChanges.child('plate-change-items').val()}
            />
        </Container>
        { 
          checkType === fullCheck && 
          <Container className="my-5 bg-light shadow" style={{borderRadius: '15px'}}>
            <MileageSection  mileageItemsCount={mileage.val()?.mileageItemsCount || 0} 
                              mileageDataItems={mileage.child('mileage-data-items').val()}
                              mileageAnomaly={mileage.val()?.mileageAnomaly}
            />
            <h6>Mileage History</h6> 
            <MileageChart   mileageItemsCount={mileage.val()?.mileageItemsCount || 0} 
                              mileageDataItems={mileage.child('mileage-data-items').val()}
            />         
          </Container> 
        }
        <Container className="my-5 bg-light shadow" style={{borderRadius: '15px'}}>
            <ThankYouSection/>
        </Container>
        </div>
    ) || null
    
}

export default CheckReport






