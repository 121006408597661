export function formattedDateTime(timestamp) {
    if (isNaN(timestamp)) {
        return ""
    }
    const d = new Date(timestamp)
    return d.toLocaleString()
}

export function formattedDate(timestamp) {
    if (isNaN(timestamp)) {
        return ""
    }
    const d = new Date(timestamp)
    return d.toLocaleDateString()
}