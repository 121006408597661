import React from 'react';
import {Helmet} from 'react-helmet';
import PageTitle from '../../Shared/Page/pageTitle';

const ContactUs = () => (

    <>
    <Helmet>
            <title>Contact Us</title>
            <meta name="description" content="Get in touch with us here at autoClarity: Car Check &amp; Value" />
            <link rel="canonical" href="https://autoclarity.co.uk/contact" />
        </Helmet>
         <PageTitle title='Contact Us' lead="How to get in touch." />

        <div className='container'>
            <p>
                At autoClarity we want to improve the experience of buying a used vehicle. We provide tools that help.
                If you've got a problem with data, or an idea to improve our service, then please let us know.
            </p>

            <p>
                Please note that we do not provide a vehicle check service outside of our dedicated App.
            </p>

            <h2  className='mt-5'>Email Us: <strong><a href='mailto:admin@autoclarity.co.uk'>admin@autoclarity.co.uk</a></strong></h2>
            <p>We'll get back to you as soon as possible</p>

            <p>
                If you're contacting us about a vehicle check, please provide the vehicle registration plate along with a description of your issue.
            </p>
        </div>
    </>
)

export default ContactUs;