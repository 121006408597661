import React from 'react';
import {Helmet} from 'react-helmet';
import PageTitle from '../../Shared/Page/pageTitle';

const Cookie = () => (

    <>
        <Helmet>
            <title>Cookie Policy</title>
            <meta name="description" content="autoClarity cookie policy" />
            <link rel="canonical" href="http://autoclarity.co.uk/policies/cookies" />
        </Helmet>
        <PageTitle title='Cookie Policy' lead="How and why we use cookies." />
        <div className='container'>

            <h2 className='mt-5'>What are cookies?</h2>
            <p>
                Cookies are widely used tiny text files that are installed on a computer or mobile device via the browser (e.g. Microsoft Edge or Internet Explorer, Apple Safari, Google Chrome, or Firefox) when an individual visits a website.
            </p>
            <p>
                Cookies allow websites to recognise that a user on an individual computer has previously visited the site. The cookies save some information about that user for when they access the site again in the future.
            </p>

            <p>
                If you do not consent to us storing and using cookies on your device, you can normally disable them in your browser settings.
                Alternatively, you can refrain from using the site.
            </p>

            <hr/>
            <h2 className='mt-5'>Cookie consent</h2>
            <p>By using our website, or our social media pages, you agree that unless you disable cookies in your settings, then we may place them
                on your device to be used in accordance with our Policy.
            </p>

            <hr/>
            <h2 className='mt-5'>Cookies Used</h2>
            <p>
                We may place essential cookies on your device to remember settings. For example, to save you consenting to our cookie policy 
                every time you visit the site, a cookie is used to store that you've previously given consent.
            </p>
            <p>
                We use Google Analytics to measure performance and track events so that we can make changes or improvements to our services.
            </p>
            <p>
                We use Google AdSense to support the site which delivers advertising from Google and other 3rd parties. These advertisers may use cookies to personalise the ads that they serve based on a user's visits to this website and other websites. 
            </p>
        </div>
    </>

)

export default Cookie;