import React from 'react';
import Button from 'react-bootstrap/Button';


import { withRouter } from 'react-router-dom';

const PageNotFound = (props) => (
    <div className='container mt-5'>
        <h3>
            Oops, we're sorry, but that page doesn't appear to exist...
        </h3>
        <p>
            It may have moved or been deleted, or you may have entered an invalid URL.
        </p>
        <Button onClick={props.history.goBack}>Back</Button>
    </div>
) 
export default withRouter(PageNotFound);