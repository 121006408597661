import React from 'react';

import Nav  from 'react-bootstrap/Nav';
import Navbar   from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';

import { LinkContainer } from 'react-router-bootstrap';

import * as ROUTES from '../../../constants/routes';
import { withRouter } from 'react-router-dom';

import './navigation.css';

import Logo from '../../../assets/autoclarity_image.svg';

import { useAuth } from './../../Hooks/useAuth';

const handleSignout = (auth, props) => {
  auth.signout()
  props.history.push(ROUTES.LANDING)
}


const Navigation = (props) => {

  const auth = useAuth();

  return (
    <Navbar className="navbar-custom py-1 sticky-top" variant="dark" expand="md" collapseOnSelect="true">
      <Navbar.Brand >
        <LinkContainer to={ROUTES.LANDING}>
          <Nav.Link ><img src={Logo} alt="autoclarity"/></Nav.Link>
        </LinkContainer>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav"/>
      <Navbar.Collapse id="basic-navbar-nav">
               <>
                <Nav>
                <LinkContainer to={ROUTES.PRODUCTS}>
                  <Nav.Link active={false}>Products</Nav.Link>
                </LinkContainer>
                <LinkContainer to={ROUTES.SAMPLE_REPORT}>
                  <Nav.Link active={false}>Sample Report</Nav.Link>
                </LinkContainer>
                <LinkContainer to={ROUTES.VEHICLE_REMINDERS}>
                  <Nav.Link active={false}>Vehicle Reminders</Nav.Link>
                </LinkContainer>
                <Nav.Link href={ROUTES.APP_DOWNLOAD}>
                  Get The App
                </Nav.Link>
                <LinkContainer to={ROUTES.ACCOUNT}>
                      <Nav.Link active={false}>My Account</Nav.Link>
                </LinkContainer>
                { auth && auth.user &&
                    <Button onClick={ () => handleSignout(auth, props) }>Sign Out</Button>
                }
                </Nav>
              </>
              
        
      </Navbar.Collapse>
    </Navbar>
)};

export default withRouter(Navigation);