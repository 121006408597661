import React from 'react'


export const UsageBanner = () => {

    return (
        <div className="banner">
        <span>I understand that any purchases made can only be used in the autoClarity: Car Check &amp; Value app for iPhone or iPad.</span>
        </div>
    )

}
