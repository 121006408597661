import { useState, useEffect } from 'react'
import firebase from '../Firebase/firebase'


export const useFetchAllUserListings = (user) => {

    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [listings, setListings] = useState(null)

    useEffect(
         () => {

            async function getIndexes(uid) {
                return await firebase.database()
                        .ref('user-lookups')
                        .child(uid)
                        .orderByChild('vrm')
                        .once('value')
            }

            async function getListing(listingKey) {
                return await firebase.database()
                        .ref('listings')
                        .child(listingKey)
                        .once('value')
            }

            async function getAllListings(snapshots) {
                let promises = []
                snapshots.forEach(snapshot => {
                    promises.push(getListing(snapshot.key))
                })
                return await Promise.all(promises)
            }

            try {
                if (user) {
                    (async (uid) => {
                        let indexes =  await getIndexes(uid)
                        return await getAllListings(indexes)
                    })(user.uid).then(listingSnapshots => {
                        setLoading(false)
                        setListings(listingSnapshots)
                    })   
                } 
            } catch (error) {
                setError(error)
            }
            
            return
        }, [user]
    )

    return {
        error,
        loading,
        listings
    }
}
