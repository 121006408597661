import React from 'react';
import {Helmet} from 'react-helmet';
import Card from 'react-bootstrap/Card';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import CheckSummary from '../../assets/check_summary.png';
import CheckDetails from '../../assets/check_details.png';
import CheckTimeline from '../../assets/check_timeline.png';
import VehicleSpec from '../../assets/vehicle_specification.png';
import LandingPage from '../../assets/landing_page.png';
import VehicleSummary from '../../assets/vehicle_summary.png';
import PageTitle from '../Shared/Page/pageTitle';

const ReportCard = (props) => (
    <Card className='text-center'>
                            <Card.Header>{props.header}</Card.Header>
                            <Card.Body>
                                <Card.Text>
                                        {props.para1}
                                </Card.Text>
                                <Card.Text>
                                        {props.para2}
                                </Card.Text>
                            </Card.Body>
                            <span className='text-center'><Card.Img className='mb-1' style={{maxWidth: '290px'}} variant="bottom" src={props.imgSrc}/></span>
                        </Card>
)


const SampleReport = () => {
    return (
        <>
        <Helmet>
            <title>Sample Full Check Screenshots</title>
            <meta name="description" content="autoClarity: Car Check &amp; Value app for iPhone Full Check report screenshots" />
            
            <link rel="canonical" href="https://autoclarity.co.uk/sample-report" />
        </Helmet>
        <PageTitle title='Sample Full Check Report' lead="Screenshots from the app."/>
        <div>
            <Container>
                <Row>
                    <Col xs={12} lg={6} className="mt-5">
                        <ReportCard header='Get Started'
                                    para1='Use the vehicle registration plate to lookup your vehicle.'
                                    para2="Tap ANPR and point your phone's camera at the vehicle's number plate. 
                                    Not near the vehicle? No problem. Tap Voice and speak in the number plate. Prefer to type it in? Tap the plate to show the keyboard. The choice is yours."
                                    imgSrc={LandingPage}></ReportCard>
                    </Col>
                    <Col xs={12} lg={6} className="mt-5">
                        <ReportCard header = 'Vehicle Summary'
                                    para1 = "The Vehicle Summary screen provides an overview of the vehicle. See important information such as MOT and tax due dates, tax cost, number of keepers, and more."
                                    para2 = "Here you can reveal further information by using one of our paid checks or get a valuation."
                                    imgSrc = {VehicleSummary}></ReportCard>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} lg={6} className="mt-5">
                        <ReportCard header = 'Check Summary'
                                    para1 = "The paid check report screen shows a summary of the most important data items colour coded using red, amber or green to quickly highlight any areas for concern."
                                    para2 = 'Tapping any of the coloured squares scrolls you to the detail for that item.'
                                    imgSrc = {CheckSummary}></ReportCard>

                    </Col>
                    <Col xs={12} lg={6} className="mt-5">
                        <ReportCard header = 'Check Details'
                                    para1 = "Navigating to any of the detail items shows you all the information that we have about the issue."
                                    para2 = "For outstanding finance or high risk records, contact details are shown where available with a reference number that you can quote to find out the status."
                                    imgSrc = {CheckDetails}></ReportCard>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} lg={6} className="mt-5">
                        <ReportCard header = 'Timeline View'
                                    para1 = "The timeline shows the vehicle history in chronological order, with red, amber, green indicators to show severity."
                                    para2 = "The timeline will populate with full MOT history reports, keeper changes, plate changes and lots more. Adding a Full or Basic Check will add more events to the timeline, so you can see exactly when events occurred and the details."
                                    imgSrc = {CheckTimeline}></ReportCard>
                    </Col>
                    <Col xs={12} lg={6} className="mt-5">
                        <ReportCard header = 'Specification View'
                                    para1 = "Switch to the specification view to show you technical information about the vehicle."
                                    para2 = "This view shows vehicle performance and consumption, the engine number and specification, weights and dimensions, the last 5 digits of the VIN and more!"
                                    imgSrc = {VehicleSpec}></ReportCard>
                    </Col>
                </Row>
            </Container>
        </div>
        </>
    )
}

export default SampleReport;