import React from 'react';
import {Helmet} from 'react-helmet';
import PageTitle from '../../Shared/Page/pageTitle';

const GeneralTermsConditions = () => (

    <>
        <Helmet>
            <title>Terms and Conditions</title>
            <meta name="description" content="autoClarity general terms and conditions" />
            <link rel="canonical" href="http://autoclarity.co.uk/policies/terms-conditions" />
        </Helmet>
        <PageTitle title='Terms and Conditions' lead="Terms and Conditions of the use of our website and application services." />

        <div className='container'>

            <h2 className='mt-5'>General Terms and Conditions</h2>
            <p>
                The service is owned and operated by ClearCut IT Ltd ("Us" and "We"). The data we provide you is provided to us from various third party sources. 
                When you use this service you are subject to these terms and conditions ("Terms") and you have agreed to be legally bound by them.
            </p>
            <p>
                If this is not agreeable to you, then you should not use our service. 
            </p>

            <hr/>

            <h2 className='mt-5'>The terms of our business with You</h2>
            <ol>
            
                <li>
                    Please read these Terms carefully before using this website (“Site”) or our app ("App"). Your use of the Site or App constitutes acceptance of these terms and conditions.
                </li>
                <li>
                    We reserve the right to change these Terms by posting the changes online. Your continued use of the Site or App after changes are posted constitutes your acceptance of this agreement as modified.
                </li>
                <li>
                    We take reasonable and prudent care to ensure the information supplied to You is accurate. 
                    We make absolutely no guarantee that the information we provide to You is correct. 
                    We provide You with this data on an "as is" basis and our only representation to you is that it is an accurate copy of information which has been provided to Us.
                </li>
                <li>
                    You agree not to create multiple identities to access our services. Creating multiple identities i.e using multiple phone numbers or Facebook identities to create new accounts 
                    is strictly forbidden. We reserve the right to block accounts from using our services where we believe this may be happening.
                </li>
                <li>
                    The contract formed by our agreement to fulfill Your request for a data check is between You and Us and is exclusively for Your use only. 
                    You may not pass this information to any third parties and if You breach these terms, any third party in possession of data provided by us to You will have no warranty whatsoever. 
                    Our data provides You with no indication as to the road worthiness or general condition of any vehicle you have checked. 
                    You have no rights to cancel the contract and the Consumer Protection (Distance Selling) Regulations 2000 does not apply. 
                </li>
             </ol>   
            <h3>Vehicle Lookup, Check and Valuation Service</h3>
            <ol>
                <li>
                    We have no control over how our third party providers supply data or the time it may take them to do so. We will provide you the latest data we have available.
                </li>
                <li>
                    We guarantee to you that the data we provide You will accurately mirror the data held by the third parties from whom we collect and/or receive. This specifically excludes any liability by Us for the accuracy of the data held by third parties upon which we report and we specifically hold no liability for any errors which might exist within that data unless specifically covered by our Data Guarantee found here
                </li>
                <li>
                    You understand the data we provide you must be used in conjunction with all accepted good and prudent practice when purchasing a motor vehicle and our data should not be relied upon in isolation. You should not make any inference that our data indicates the vehicle has a particular value, save for the data we may provide you as to published data for specific models in pre-classified condition.
                </li>
                <li>
                    The purchase of a motor vehicle carries risks and our service is just one step you can take to protect yourselves from those who may seek to misrepresent a vehicle or to defraud You. 
                    Our data cannot identify a "ringed" or "Cloned" vehicle. These are vehicles which carry an incorrect number plate and/or VIN number. We offer no compensation or guarantee in any regard if the vehicle you purchased was displaying incorrect number plates at the time You purchased data from us.
                </li>

                <li>
                    Stolen Vehicles may only be reported to you if the registration number of the vehicle you have provided to Us has been recorded by the police as being reported stolen
                </li>

                <li>
                    The limit of liability and extent of responsibilities as detailed in these Terms apply equally and severally to Us, our directors, employees, agents and/or other representatives.
                </li>

                <li>
                    We do not limit your statutory rights as a consumer by the imposition of these Terms.
                </li>

                <li>
                    We pass to the Police Your details if you seek a report on a vehicle which is flagged by the Police as being reported as stolen and You agree we may do so. 
                </li>
            </ol>

            <h3>Vehicle Reminders Service</h3>
            <p>
                App users may use the vehicle reminder service to receive notifications on their device when an important date is due, for example, the MOT due date for the vehicle. 
                We provide this service on the understanding that We take absolutely no responsibility for any losses or fines incurred as a result of a user missing a due date. 
            </p>
            <ol>
                <li>
                We can not guarantee that a notification will be received by the User due to conditions outside of our control, for example, the user disabling the notification service on their device. 
                </li>
                <li>
                We do not automatically maintain reminder due dates. To continue receiving notifications after a due date has passed, the user must enter the App and maintain the reminder. We will attempt to send
                a notification when a due date has passed.
                </li>
                <li>
                MOT and Tax due dates can be retrieved from DVLA records if the user requeries the vehicle and creates a new reminder or updates an existing reminder. To maintain Service or Insurance due dates,
                users must obtain that information from their insurer or service provider.
                </li>
            </ol>
                
                
            

            <hr/>
            <h2 className='mt-5'>Our Intellectual Property</h2>
            <p>
            Unauthorised use of our content including reproduction, storage, modification, distribution or publication without the prior written consent of ClearCut IT Ltd is prohibited. Documents (including information, images, photos, logos, names and icons without the prior written permission of the copyright holders may not be downloaded, altered or adapted for any purpose.
            </p>

            <hr/>
            <h2 className='mt-5'>Territory (England, Scotland and Wales)</h2>
            <p>
                Our service is for use by residents of England, Scotland and Wales. If You are not a resident of the Territory You may not use our service. By using our service you representing to Us that your residency complies with our Terms. 
            </p>

            <hr/>
            <h2 className='mt-5'>Law</h2>
            <p>
                Your use of our service and these Terms shall be governed by and construed in accordance with the laws of England and You agree to submit to the jurisdiction of the English courts in connection with any dispute between You and Us.
            </p>

            <hr/>
            <h2 className='mt-5'>Claims</h2>
            <p>
                Any claims made by You under these Terms or our Additional Guarantee will be determined by a qualified Arbitrator appointed by us at our sole discretion. 
            </p>

        </div>

    </>

)


export default GeneralTermsConditions;