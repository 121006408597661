import React, {  useEffect } from 'react'
import ReactGA from 'react-ga'
import { Route } from 'react-router-dom'


const TrackPageChange = (pathname, search = '', options) => {
    const page = pathname + search
      const { location } = window
      ReactGA.set({
        page,
        location: `${location.origin}${page}`,
        ...options
      })
      ReactGA.pageview(page)
}

const GoogleAnalytics = props => {
    useEffect(() => {
        TrackPageChange(props.location.pathname, props.location.search, props.options)
    },[props.location.pathname, props.location.search, props.options])
    return (null)
}

const LogEvent = (category, action, label) => {
    ReactGA.event({
        category: category,
        action: action,
        label: label
    })
}

const RouteTracker = () =>
  <Route component={GoogleAnalytics} />

const init = (options = {}) => {
  const env = process.env || {}
  const isGAEnabled = !!env.REACT_APP_GA_TRACKING_ID

  if (isGAEnabled) {
    ReactGA.initialize(
      env.REACT_APP_GA_TRACKING_ID, {
        debug: env.REACT_APP_GA_DEBUG === 'true',
        ...options
      }
    )
  }

  return isGAEnabled
}

export default {
    GoogleAnalytics,
    RouteTracker,
    init,
    LogEvent
  }