import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';

import Navigation from '../Shared/Navigation';

import Footer from '../Shared/Footer';
import ScrollToTop from '../Shared/scrollToTop';

import { Helmet } from 'react-helmet';

// import routes
import Routes from './routes';

// import font awesome icons
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCheck, faTimes, faAngleLeft, faStar, faRocket, faMeteor,
         faBurn, faMicrophoneAlt, faVideo, faCarCrash, faExclamationTriangle,
          faExclamationCircle, faCheckCircle, faMoneyBillAlt, faUsers, faShieldAlt,
        faTools, faLongArrowAltRight, faLongArrowAltLeft, faEye, faFileInvoice, faTachometerAlt} from '@fortawesome/free-solid-svg-icons';

//Google Analytics
import GA from '../../utils/googleAnalytics';

import { ProvideAuth } from '../Hooks/useAuth.js';

library.add(faCheck, faTimes, faAngleLeft, faStar, faRocket, faMeteor, faBurn, fab, 
            faMicrophoneAlt, faVideo, faCarCrash, faExclamationTriangle, faExclamationCircle,
            faCheckCircle, faMoneyBillAlt, faUsers, faShieldAlt, faTools, faLongArrowAltRight,
            faLongArrowAltLeft, faEye, faFileInvoice, faTachometerAlt);

const App = () => {

  return (
      <ProvideAuth>
        <Router>
          { GA.init() && <GA.RouteTracker /> }
          <ScrollToTop>
            <Helmet>
              <title>Car Check and Valuation app for iPhone</title>
              <link rel="canonical" href="https://autoclarity.co.uk" />
              <meta name="keywords" content="car check, car history, vehicle check, vehicle history, bike check, bike history, motorcycle check, motorcycle history, van check, van history, free car check, stolen, written off, valuation, vehicle value" />
              <meta name="description" content="The essential app for iPhone if you're buying a used car, bike or commercial vehicle. Download it free from the App Store. Get free vehicle checks showing full MOT history, keepers, plate changes etc. Get market valuations. On your iPhone or iPad."/>
              <meta property="og:site_name" content="autoClarity" />
              <meta property="og:type" content="website" />
              <meta property="og:url" content="https://autoclarity.co.uk" />
              <meta property="og:title" content="Car Check and Valuation app for iPhone"/>
              <meta name="twitter:card" content="summary_large_image"/>
              <meta name="twitter:site" content="@autoClarityApp"></meta>
            </Helmet>
            <div className="site">
                <div className="site-content">
                  <Navigation />
                  <Routes/>
                </div>
                <Footer/>
                <CookieConsent>
                  We use cookies on our website. Cookies are used to collect information about how you interact with the site. They help us make improvements and customise
                  your browsing experience. They may be used for the collection and sharing of data for the personalization of advertising. See our Cookie Policy. Please confirm you accept our use of cookies.
                </CookieConsent>
            </div>
          </ScrollToTop>
        </Router>
      </ProvideAuth>            
  );

};

export default App;