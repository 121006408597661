import React from 'react'
import Checkout from './checkout'
import {Breadcrumb, Image} from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import BreadcrumbItem from 'react-bootstrap/BreadcrumbItem'
import * as ROUTES from '../../constants/routes'
import CheckImage from '../../assets/AC_Check.png'
import ValueImage from '../../assets/AC_value.png'
import Logo from '../../assets/ac_logo_256px.png'
import { UsageBanner } from './usageBanner'
import './checkout.css'

const AccountBreadcrumb = () => {
    return <Breadcrumb>
                <LinkContainer to={ROUTES.ACCOUNT}>
                    <BreadcrumbItem>Account</BreadcrumbItem>
                </LinkContainer>
                <BreadcrumbItem active>Checkout</BreadcrumbItem>
           </Breadcrumb>
}

const CheckoutContainer = (props) => {
    return (
        <div>
            <AccountBreadcrumb/>
            <div className='sr-root'>
                <div className='sr-main'>
                    <Image className='logo mb-1' src={Logo} roundedCircle />
                    <Checkout productKey={props.location.state.productKey} />
                </div>
                <div className="sr-content">
                    <div className="checkout-image-stack">
                        <img
                        src={CheckImage}
                        width="225"
                        height="150"
                        alt=""
                        />
                        <img
                        src={ValueImage}
                        width="225"
                        height="150"
                        alt=""
                        />
                    </div>
                </div>
            </div>
            <UsageBanner/>
        </div>
    )
}

export default CheckoutContainer