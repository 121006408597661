export const LANDING = '/';
export const PRODUCTS = '/products';
export const SAMPLE_REPORT = '/sample-report';
export const CHECK_REPORT = '/check-report/:id';
export const USED_CAR_SEARCH = '/used-car-search';
export const COOKIE_POLICY = '/policies/cookies';
export const PRIVACY_POLICY = '/policies/privacy';
export const INFORMATION_SECURITY_POLICY = '/policies/information-security';
export const VEHICLE_CHECK_GUARANTEE = '/policies/vehicle-check-guarantee';
export const GENERAL_TERMS = '/policies/terms-conditions';
export const USED_CAR_BUYING_GUIDE = '/guides/used-car-buying-guide';
export const SHOULD_I_GET_A_CHECK = '/guides/should-i-get-a-vehicle-check';
export const FREE_CAR_CHECK = '/free-car-check';
export const CONTACT_US = '/contact';
export const ABOUT = '/about';
export const VEHICLE_REMINDERS = '/vehicle-reminders';
export const ULEZ_CHECK = '/ulez-check';
export const ACCOUNT = '/account';
export const SIGNIN = '/signin';
export const CHECKOUT = '/checkout';
//EXTERNAL LINKS
export const APP_DOWNLOAD = 'https://apps.apple.com/gb/app/autoclarity/id1301362878?ls=1';


//BASE URL
export const BASE_URL = "https://autoclarity.co.uk";