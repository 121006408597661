import React from 'react'
import PageTitle from '../Shared/Page/pageTitle'
import { Link } from 'react-router-dom'
import * as ROUTES from '../../constants/routes'
import {Helmet} from 'react-helmet'
import CompareChecks from '../Products/compareChecks'
import { LinkContainer } from 'react-router-bootstrap'
import Links from '../Shared/ButtonLinks'
import Button from 'react-bootstrap/Button'
 


const FreeCarCheck = () => (
    <>
    <Helmet>
        <title>Free Car Check on autoClarity: Car Check &amp; Value app for iPhone</title>
        <meta name="description" content="autoClarity: Car Check &amp; Value app for iPhone free car check reveals lots of useful vehicle history. Download the free app from the App Store and discover for yourself." />
        <meta property="og:title" content="Free Car Check on autoClarity: Car Check &amp; Value app for iPhone"/>
        <link rel="canonical" href="https://autoclarity.co.uk/free-car-check" />
    </Helmet>
    <PageTitle title='Free Car Check' lead="
    Download the free autoClarity: Car Check &amp; Value app for iPhone, sign up and enter any UK vehicle registration number.
    "/>

    <div className='container'>
        <h2>Free Checks for Cars, Bikes and Commercial Vehicles</h2>
        <p>
            autoClarity Car Checks are available in the <a href={ROUTES.APP_DOWNLOAD}>free to download</a> app. Find out when your MOT or Tax is due, tax cost, full MOT history, <Link to={{ pathname: ROUTES.ULEZ_CHECK}}>ULEZ exemption</Link>, previous keepers and lots more, using our free check.
        </p>
        <p>
            If you need to check more vehicles than the free quota allows, you can top-up by buying one of our <Link to={{ pathname: ROUTES.PRODUCTS, hash: "#vehicleDescriptions"}}>Vehicle Description</Link> products. Your free quota renews after a few days.
        </p>
        <small className='text-muted'>
            A vehicle lookup is valid all day on the same day it is looked up. This means you can lookup the same vehicle as often as you wish on the same day and consume only one free or paid for credit. 
        </small>
            
        <div className='text-center'>
        <Links.AppDownloadButton />
        </div>

        <CompareChecks/>
        <div className='text-center'>

              <LinkContainer to={ROUTES.PRODUCTS}>
                <Button className='mx-1' variant="primary">See All Products</Button>
              </LinkContainer>
            </div>
    </div>
    </>
)

export default FreeCarCheck;

