import React from 'react';
import * as ROUTES from '../../../constants/routes';
import { Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



const Footer = () => (
    <div id="footer" className='border-top mt-5 shadow' >
        <footer className='footer mt-auto py-3 shadow'>
            <Container>
                <Row>
                    <Col>
                        <h6>Policies</h6>
                        <ul>
                            <li>
                                <Link to={ROUTES.GENERAL_TERMS}>General Terms and Conditions</Link>
                            </li>
                            <li>
                                <Link to={ROUTES.PRIVACY_POLICY}>Privacy Policy</Link>
                            </li>
                            <li>
                                <Link to={ROUTES.COOKIE_POLICY}>Cookie Policy</Link>
                            </li>
                            <li>
                                <Link to={ROUTES.INFORMATION_SECURITY_POLICY}>Information Security Policy</Link>
                            </li>
                            <li>
                                <Link to={ROUTES.VEHICLE_CHECK_GUARANTEE}>Vehicle Check Guarantee</Link>
                            </li>
                        </ul>
                    </Col>
                    <Col>
                        <h6>Guides</h6>
                        <ul>
                            <li>
                                <Link to={ROUTES.USED_CAR_BUYING_GUIDE}>Used Car Buying Guide</Link>
                            </li>
                        </ul>
                    </Col>
                    <Col>
                        <h6>Support</h6>
                            <ul>
                                <li>
                                    <Link to={ROUTES.CONTACT_US}>Contact Us</Link>
                                </li>
                                <li>
                                    <Link to={ROUTES.ABOUT}>About</Link>
                                </li>
                            </ul>           
                    </Col>
                    <Col>
                        <h6>App</h6>
                            <ul>
                                <li>
                                    <a href={ROUTES.APP_DOWNLOAD}>Get The App</a>
                                </li>
                                <li>
                                    <Link to={ROUTES.FREE_CAR_CHECK}>Free Car Check</Link>
                                </li>
                                <li>
                                    <Link to={ROUTES.VEHICLE_REMINDERS}>Vehicle Reminders</Link>
                                </li>
                                <li>
                                    <Link to={ROUTES.ULEZ_CHECK}>ULEZ Check</Link>
                                </li>
                            </ul>        
                    </Col>
                </Row>
            </Container>
        </footer>
    </div>
)

export default Footer;