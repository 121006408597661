import { useState, useEffect } from 'react'
import firebase from '../Firebase/firebase'

export const useFetchListingWithSupplementary = (listingKey) => {
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [listing, setListing] = useState(null)
    const [vehicleSupplementary, setVehicleSupplementary] = useState(null)

    useEffect(
         () => {

            async function getSupplementary(listingKey) {
                return await firebase.database()
                        .ref('vehicle-supplementary')
                        .child(listingKey)
                        .once('value')
            }

            async function getListing(listingKey) {
                return await firebase.database()
                        .ref('listings')
                        .child(listingKey)
                        .once('value')
            }

            if (listingKey) {
                try {
                    getListing(listingKey).then(listingSnapshot => {
                        setListing(listingSnapshot)
                        setLoading(false)
                    })
    
                    getSupplementary(listingKey).then(vehicleSuppSnap => {
                        setLoading(false)
                        setVehicleSupplementary(vehicleSuppSnap)
                    })
                } catch (error) {
                    setError(error)
                }
            }
            return
        }, [listingKey]
    )

    return {
        error,
        loading,
        listing,
        vehicleSupplementary
    }
}