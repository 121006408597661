import React from 'react';
import PageTitle from '../../Shared/Page/pageTitle'


const ShouldIGetACheck = () => (
    <>
    <PageTitle title='Do I Need A Vehicle History Check' lead="Read on to find out why getting a vehicle history check might save you a fortune."/>

    <div className='container'>
        <h1>What is a Vehicle Check?</h1>
    </div>
    </>
)

export default ShouldIGetACheck;