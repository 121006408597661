import React from 'react';
import PageTitle from '../../Shared/Page/pageTitle';

const InformationSecurity = () => (

    <>
    <PageTitle title='Information Security' lead="How we secure your data" />

        <div className='container'>

            <h2 className='mt-5'>Data encryption</h2>
            <p>We use cloud services provided by Google to transmit and logically isolate customer data.</p>
            <p>
                The services we use have successfully completed the ISO 27001 and SOC 1, SOC 2, and SOC 3 evaluation process, and some have also completed the ISO 27017 and ISO 27018 certification process.
            </p>
            <hr/>

            <h2 className='mt-5'>Security Practices</h2>
            <p>We restrict access to only the employees who have a business need to access personal data.</p>
            <hr/>
        </div>

    </>

)


export default InformationSecurity;