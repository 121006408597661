import React from 'react'
import {Helmet} from 'react-helmet'
import Card from 'react-bootstrap/Card'
import PageTitle from '../Shared/Page/pageTitle'
import CheckImage from '../../assets/reminder.png'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ListGroup from 'react-bootstrap/ListGroup'
import ListGroupItem from 'react-bootstrap/ListGroupItem'




const VehicleReminders = () => {
    return (
        <>
        <Helmet>
        <title>Vehicle Reminders</title>
        <meta name="description" content="autoClarity: Car Check &amp; Value app for iPhone can remind you when your MOT, tax, service or insurance are due" />      
        <link rel="canonical" href="https://autoclarity.co.uk/vehicle-reminders" />
        </Helmet>
        <PageTitle title='Vehicle Reminders' lead="Get notified about MOT, Tax and other important due dates"/>
        <Container>
            <Card>
                <Row>
                    <Col md={4}>
                        <Card.Img className="px-1 py-1" src={CheckImage} alt="vehicle reminders app screenshot image" />
                    </Col>
                    <Col>
                        <Card.Body md={8}>
                            <Card.Title>Receive notifications on your iPhone and iPad</Card.Title>
                            <Card.Text>
                                Lookup your vehicles on autoClarity: Car Check &amp; Value app and create reminders to get 
                                notified when your:
                            </Card.Text>
                            <ListGroup variant="flush">
                                <ListGroupItem>MOT is due</ListGroupItem>
                                <ListGroupItem>Tax is due</ListGroupItem>
                                <ListGroupItem>Insurance is due</ListGroupItem>
                                <ListGroupItem>Service is due</ListGroupItem>
                            </ListGroup>
                        </Card.Body>       
                    </Col>
                </Row>
            </Card>
        </Container>
            
        </>
    )
}

export default VehicleReminders