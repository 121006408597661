import React from 'react';
import PageTitle from '../../Shared/Page/pageTitle'
import ListGroup from 'react-bootstrap/ListGroup';


const UsedCarBuyingGuide = () => (
    <>
    <PageTitle title='Used Car Buying Guide' lead="
    Follow the guidelines below to minimise your risk of buying a vehicle being sold fraudulently, or one with bad history or outstanding debt.
    "/>

    <div className='container'>
        <section className='check'>
        <ListGroup as='ul' className='list-unstyled'>
            <ListGroup.Item as='li' className='px-5'>
                Ensure you know exactly who you are buying the vehicle from. Arrange to meet at the premises and enter them, so you aren't doing a deal on the street.
            </ListGroup.Item>
            <ListGroup.Item as='li' className='px-5'>
                If you are buying from a dealer, ensure they supply you with a printed invoice giving all the company details, VAT registration number and full details of the vehicle.
            </ListGroup.Item>

            <ListGroup.Item as='li' className='px-5'>
                If you are buying privately, arrange to meet at the address on the V5C. Ask the seller for identification to prove they live at the address to ensure the seller is the keeper.
            </ListGroup.Item>

            <ListGroup.Item as='li' className='px-5'>
                Check the vehicle has a full DVLA issued V5C. Compare the issue date with that shown on the vehicle timeline in the autoClarity: Car Check &amp; Value app. If you are concerned about the authenticitiy of the V5C, contact the DVLA helpline on 0300 7906104.
            </ListGroup.Item>

            <ListGroup.Item as='li' className='px-5'>
                Use autoClarity: Car Check &amp; Value to confirm the vehicle has a valid MOT certificate. 
            </ListGroup.Item>

            <ListGroup.Item as='li' className='px-5'>
                Use autoClarity: Car Check &amp; Value to confirm the VIN matches the value found on the vehicle chassis and the documentation.
            </ListGroup.Item>

            <ListGroup.Item as='li' className='px-5'>
                Check the service history carefully, and obtain confirmation that any warranty is still effective.
            </ListGroup.Item>

            <ListGroup.Item as='li' className='px-5'>
                Check the manufacturer's warranty is valid if you believe there is one. Contact a franchised dealer or the manufacturer to check the validity of the warranty.
            </ListGroup.Item>

            <ListGroup.Item as='li' className='px-5'>
                Ensure your payment method is traceable. Pay by electronic transfer, cheque or banker's draft. The Data Guarantee does not provide protection for cash payments which exceed £1000 or 10% of the purchase price of the vehicle (whichever is the lower).
            </ListGroup.Item>

            <ListGroup.Item as='li' className='px-5'>
                Ask if the vehicle has been involved in any accidents. Inspect any repair work that has been undertaken.
            </ListGroup.Item>

            <ListGroup.Item as='li' className='px-5'>
                Get an independent mechanical inspection.
            </ListGroup.Item>

            <ListGroup.Item as='li' className='px-5'>
                Get an autoClarity valuation and question if a vehicle is being sold below market price.
            </ListGroup.Item>   

            <ListGroup.Item as='li' className='px-5'>
                Perform a Full Check on autoClarity: Car Check &amp; Value and be cautious and prudent. If something looks too good to be true, it often is.
            </ListGroup.Item> 

            <ListGroup.Item as='li' className='px-5'>
                If there is outstanding finance on a vehicle, don't take the seller's word that it has been settled. Contact the finance company using the details
                provided on the Full Check report and ask them for written confirmation by email that there are no outstanding liabilities.
            </ListGroup.Item> 

            <ListGroup.Item as='li' className='px-5'>
                DO NOT Part with any money until you are certain you have taken all precautions to ensure the seller is the owner of the vehicle and any outstanding liabilities have been settled.
            </ListGroup.Item>   


            

        </ListGroup>
        
        </section>
    </div>
    </>
)

export default UsedCarBuyingGuide;

