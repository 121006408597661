import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom'
// import route constants
import * as ROUTES from '../../constants/routes'
import { useAuth } from '../Hooks/useAuth'
import LandingPage from '../Landing'
import Products from '../Products'
import SampleReport from '../SampleReport'
import PrivacyPolicy from '../Policies/Privacy'
import InformationSecurityPolicy  from '../Policies/InformationSecurity'
import CookiePolicy from '../Policies/Cookie'
import GeneralTermsConditions from '../Policies/GeneralTermsConditions'
import VehicleCheckGuarantee from '../Policies/VehicleCheckGuarantee'
import ContactUs from '../Support/ContactUs'
import UsedCarBuyingGuide from '../Guides/UsedCarBuying'
import ShouldIGetACheck from '../Guides/ShouldIGetACheck'
import FreeCarCheck from '../FreeCarCheck'
import PageNotFound from '../NotFound'
import AboutUs from '../Support/AboutUs'
import VehicleReminders from '../VehicleReminders'
import UlezCheck from '../UlezCheck'
import SignIn from '../SignIn'
import Account from '../Account'
import CheckReport from '../CheckReport/checkReport'
import CheckoutContainer from '../Checkout'
import {PageSpinner} from '../Shared/Page/pageSpinner'

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const auth = useAuth()
  if (!auth) {
    return <PageSpinner/>
  }
  return (
    <Route {...rest} render={(props) => (
        auth.user
        ? <Component {...props} />
        : <Redirect to={{ 
                          pathname: ROUTES.SIGNIN,
                          state: { from: props.location }
                        }} />
    )} />
  )

}


const Routes = () => (
    <Switch>
      <Route path={ROUTES.PRODUCTS} component={Products} />
      <Route exact path={ROUTES.SAMPLE_REPORT} component={SampleReport} />
      <Route exact path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicy} />
      <Route exact path={ROUTES.COOKIE_POLICY} component={CookiePolicy} />
      <Route exact path={ROUTES.INFORMATION_SECURITY_POLICY} component={InformationSecurityPolicy} />
      <Route exact path={ROUTES.GENERAL_TERMS} component={GeneralTermsConditions} />
      <Route exact path={ROUTES.VEHICLE_CHECK_GUARANTEE} component={VehicleCheckGuarantee} />
      <Route exact path={ROUTES.CONTACT_US} component={ContactUs} />
      <Route exact path={ROUTES.USED_CAR_BUYING_GUIDE} component={UsedCarBuyingGuide} />
      <Route exact path={ROUTES.FREE_CAR_CHECK} component={FreeCarCheck} />
      <Route exact path={ROUTES.SHOULD_I_GET_A_CHECK} component={ShouldIGetACheck} />
      <Route exact path={ROUTES.ABOUT} component={AboutUs} />
      <Route exact path={ROUTES.VEHICLE_REMINDERS} component={VehicleReminders} />
      <Route exact path={ROUTES.ULEZ_CHECK} component={UlezCheck} />
      <Route exact path={ROUTES.SIGNIN} component={SignIn} />
      <PrivateRoute exact path={ROUTES.ACCOUNT} component={Account} />
      <PrivateRoute exact path={ROUTES.CHECKOUT} component={CheckoutContainer} />
      <PrivateRoute exact path={ROUTES.CHECK_REPORT} component={CheckReport} />
      <Route exact path={ROUTES.LANDING} component={LandingPage} />
      <Route component={PageNotFound} />
    </Switch>
)

export default Routes;