
import React from 'react';
import Links from '../ButtonLinks';


const PageTitle = (props) => (
    <div className='page-header-block text-center px-3 py-3 pt-md-5 pb-md-4 mx-auto'>
            <h1 className='page-title'>{props.title}</h1>
            <p className='lead'>{props.lead}</p>
            <Links.AppBanner/>
        </div>
)

export default PageTitle;