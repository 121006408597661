

import React from 'react'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import firebase , { uiConfig } from '../Firebase/firebase'
import Image from 'react-bootstrap/Image'
import './signin.css'
import Logo from '../../assets/ac_logo_256px.png'
import { useAuth } from '../Hooks/useAuth'
import {Redirect} from 'react-router-dom'
import Links from '../Shared/ButtonLinks'

const SignIn = (props) => {
    const auth = useAuth()
    const { from } = props.location.state || { from: { pathname: '/' }}

    // watch for state change to the auth (successful login), and redirect to original requested path or / if there wasn't one.
    if (auth.user) {
        return <Redirect to={from}/>
    }

    return (
            <div className='container mt-3'>
            <div className='my-3 mx-1 text-center border'>
                <div className='mt-3'>
                    <h4>SIGN IN</h4>
                    <Image className='logo mt-2 mb-4' src={Logo} roundedCircle />
                    <p className='px-1'>Sign in to view your credit balance and purchase checks and valuations to use on your iPhone or iPad.</p>
                    <p className='px-1'>Buying credits on our website is more cost-effective than buying them in the app.</p>
                    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} className='border-top'/>
                </div>
            </div>
            <div className='container ml-1 mr-1 px-0 text-center'>
                <div className='mt-1 py-3'>
                    <p className='small mb-0'>
                        Vehicle lookups, checks and valuations can only be performed in autoClarity app for iPhone or iPad. Download it to get started.
                    </p>
                    <Links.AppDownloadButton />
                </div>
            </div>
            </div>
    )
}

export default SignIn

