import { useState, useEffect } from 'react'
import firebase from '../Firebase/firebase'


export const useFetchProduct = (productKey) => {

    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [product, setProduct] = useState(null)

    useEffect(
        () => {
                firebase
                        .database()
                        .ref('app')
                        .child('products')
                        .child(productKey)
                        .once('value', snapshot => {
                            setLoading(false)
                            if (snapshot && snapshot.exists()) {
                                setProduct({productKey: snapshot.key, product: snapshot.val()})
                            } else {
                                setProduct(null)
                            }
                        }, error => {
                            setError(error)
                        })
                return
            }  
        , [productKey]
    )

    return {
        error,
        loading,
        product,
    }
}
