import React from 'react';
import {Helmet} from 'react-helmet';
import * as ROUTES from '../../../constants/routes';
import PageTitle from '../../Shared/Page/pageTitle';

const Privacy = () => (
        <>
        <Helmet>
            <title>Privacy Policy</title>
            <meta name="description" content="autoClarity privacy policy" />
            <link rel="canonical" href="http://autoclarity.co.uk/policies/privacy" />
        </Helmet>
        <PageTitle title='Privacy Policy' lead="We are committed to protecting and respecting your privacy" />

        <div className='container'>
            <p>
                This policy explains when and why we collect personal information about you, how we use it, the conditions under which we may disclose it to others, how we keep it safe and secure and your rights and choices in relation to your information.
            </p>

            <p>
                Any questions regarding this policy should be sent by <a href='mailto:admin@autoclarity.co.uk'>email</a>.
            </p>
            <hr/>

            <h2 className='mt-5'>Who are we?</h2>

            <p>We are ClearCut IT Ltd trading as autoClarity, a limited company registered in England and Wales (no. 10504039) </p>

            <hr/>

            <h2 className='mt-5'>How do we collection information from you?</h2>

            <p>When you visit our website or use the app, we collect information from your device. The type of information we collect is 
                the device ID, the IP address, the device type, what pages you visit and how you found our website. The website uses cookies to store
                this information. You can find out more about how we use cookies <a href={ROUTES.COOKIE_POLICY}>here</a>
            </p>

            <p>
                We use Google Analytics to help us improve our services. Google Analytics collects, monitors and analyses the information it gathers from your device. You can find out more about how Google 
                uses your data by visiting this link - <a href='https://www.google.com/policies/privacy/partners/'>How Google uses data when you use our partners' sites or apps</a>
            </p>

            <p>
                    The app shows adverts using Google AdMob to support the cost of the free vehicle lookups. Users who do not have credits in their account may see these ads. A unique identifier on your mobile device, 
                    the Advertising Identifier, is used to collect data to provision adverts. Users are offered the choice
                    whether they wish to share their browsing history with Google in order to tailor the ads to be more relevant, or not share it and see less relevant ads. The user can change their choice 
                    in the app whenever they like. This setting does not affect the amount of ads shown, it simply controls the users preference about whether they wish to share their browsing history with Google.
                </p>

            <hr/>

            <h2 className='mt-5'>What type of information is collected?</h2>

            <p>The personal information that we might collect is:</p>

            <ul>
                <li>
                    Your name, mobile phone number and/or email address.
                </li>
                <li>
                    Your IP address, device type, device ID, geographic location, the time and date of your visit and the amount of time spent on each page.
                </li>
                <li>
                    If you enter credit/debit card details into the app to purchase our vehicle data, we neither store or process that data directly.
                    Your card information is directly processed and stored by our payment provider.
                </li>
            </ul>

            <p>We endeavour to only collection the information that we require to provide our services.</p>

            <hr/>

            <h2 className='mt-5'>Who has access to your information?</h2>

            <p>We only use the information you provide us to deliver our services. We only share information with 3rd parties that is required for payment provision, analytics and advertising as described in the previous section.
                We do not sell your information to 3rd parties. Our services are hosted on
                Google cloud infrastructure, to offer the highest standards of protection.
            </p>

            <hr/>

            <h2 className='mt-5'>Your Rights</h2>

            <p>At any time, you have the following rights that we think apply:</p>

            <ul>
                <li>
                    The right to know what personal information we hold on you.
                </li>
                <li>
                    The right to have personal information corrected.
                </li>
                <li>
                    The right to have personal information erased.
                </li>
            </ul>

            <p>
                Please contact us by <a href='mailto:admin@autoclarity.co.uk'>email</a> to exercise your rights. We will endeavour to respond to 
                all requests within one month of receipt.
            </p>

            <hr/>
            <h2 className='mt-5'>Safeguarding your information</h2>
            <p>
                We endeavour to keep your information secure. We use industry standard technologies like SSL to protect any data communicated to us. 
                See our <a href={ROUTES.INFORMATION_SECURITY_POLICY}>Information Security Policy</a> for additional information.
            </p>

            <hr/>
            <h2 className='mt-5'>Use of 'cookies'</h2>
            <p>
                Like most websites, we use cookies to store small pieces of information on your device. Read our <a href={ROUTES.COOKIE_POLICY}>Cookie Policy</a> for additional information. 
            </p>


               


        </div>
        </>
)

export default Privacy;