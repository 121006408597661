import React from 'react'
import Button from 'react-bootstrap/Button'
import * as ROUTES from '../../../constants/routes'
import { LinkContainer } from 'react-router-bootstrap'
import GA from '../../../utils/googleAnalytics'

const CheckTermsLink = () => (
    <LinkContainer to={`${ROUTES.VEHICLE_CHECK_GUARANTEE}`}>
        <Button variant='link' className='align-baseline p-0'>Vehicle Check Guarantee</Button>
    </LinkContainer>
)

const SampleReportButton = () => (
    <LinkContainer to={`${ROUTES.SAMPLE_REPORT}`}>
        <Button className='align-baseline'>Sample Report</Button>
    </LinkContainer>
)

const AppDownloadButton = () => (
    <Button href={ROUTES.APP_DOWNLOAD} 
            className='apple-download my-2' 
            variant="link"
            onClick={() => 
                GA.init() && GA.LogEvent('CONVERSION', 'App download button clicked', 'DOWNLOAD_APP')
            }
    />
)

const AppBanner = () => {

   return <a href="https://apps.apple.com/gb/app/autoclarity-car-check-value/id1301362878?itscg=30200&amp;itsct=apps_box" style={{width: '170px', height: '170px', borderRadius: '22%', overflow: 'hidden', display: 'inlineBlock', verticalAlign: 'middle'}}><img src="https://is1-ssl.mzstatic.com/image/thumb/Purple124/v4/47/6d/50/476d504d-3493-1ddf-7327-3e8730add523/AppIcon-0-0-1x_U007emarketing-0-0-0-7-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/540x540bb.jpg" alt="autoClarity: Car Check &amp; Value" style={{width: '170px', height: '170px', borderRadius: '22%', overflow: 'hidden', display: 'inline-block', verticalAlign: 'middle'}}></img></a>

}

export default { CheckTermsLink, SampleReportButton, AppDownloadButton, AppBanner }