
import {useEffect} from 'react';
import { withRouter } from 'react-router-dom';



const ScrollToTop = ({ children, location: { pathname, hash } }) => {
      useEffect(() => {
        if (hash === "") {
          window.scrollTo(0, 0);
        }
      }, [pathname, hash]);
  
    return children || null;
}
  
  export default withRouter(ScrollToTop);