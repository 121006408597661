
import React, { useState } from 'react'
import { CardElement, injectStripe } from 'react-stripe-elements'
import PaymentsAPI from '../../utils/paymentsAPI'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import { LinkContainer } from 'react-router-bootstrap';
import * as ROUTES from '../../constants/routes'
import './checkoutForm.css'

const CheckoutForm = (props) => {
    const [processing, setProcessing] = useState(false)
    const [error, setError] = useState(null)
    const [succeeded, setSucceeded] = useState(false)
    const [email, setEmail] = useState(props.user.email)

    const handleSubmit = async (ev) => {

        if (!processing) {
            ev.preventDefault()
            setProcessing(true)

            //create po and payment intent
            try {

                const payload = await PaymentsAPI.createPayment(props.stripe, props.user, email, props.product)

                setProcessing(false)

                if (payload.error) {
                    setError(`Payment failed: ${payload.error.message}`)
                    console.log('ERROR: ', payload.error)
                } else {
                    setError(null)
                    setSucceeded(true)
                }
            } catch (error) {
                setError(error.message)
                setProcessing(false)
            }
        }
    }

    const ShowAlert = () => {
        return error && 
        <Alert variant='danger' onClose={() => setError(null)} dismissible>
            <Alert.Heading>Oops! Something went wrong...</Alert.Heading>
            <p>
                {error}
            </p>
        </Alert>
    }

    const renderSuccess = () => {
        return (
          <div className="sr-field-success message">
            <h2>Payment Succeeded</h2>
            <p>Thank you! Your account has been credited. Open the autoClarity: Car Check &amp; Value app on your iPhone or iPad to use your credits.</p>
            <LinkContainer to={ROUTES.ACCOUNT}>
                <Button>My Account</Button>
            </LinkContainer>
          </div>
        )
    }

    const renderForm = () => {

        var style = {
            base: {
              color: "#32325d",
              fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
              fontSmoothing: "antialiased",
              fontSize: "16px",
              "::placeholder": {
                color: "#aab7c4"
              }
            },
            invalid: {
              color: "#fa755a",
              iconColor: "#fa755a"
            }
        };

        return (

            <div className='checkout-form'>
                <ShowAlert />
                <div className='sr-payment-form'>
                    <form onSubmit={handleSubmit}>
                        <h4>Credit my account with a {props.product.product.description} - £{props.product.product.webPrice}</h4>
                        <div className='sr-combo-inputs mt-3'>
                            <div className='sr-combo-inputs-row'>
                                <input
                                    type="text"
                                    id="email"
                                    placeholder="Receipt Email"
                                    autoComplete="email"
                                    required
                                    value={email || ""}
                                    onChange={e => setEmail(e.target.value)}
                                />
                            </div>
                            <div className='sr-combo-inputs-row'>
                                <CardElement className="sr-input sr-card-element" style={style} />
                            </div>
                        </div>
                        <div className='mt-3'>
                            {!succeeded && (<Button type="submit" disabled={processing}> {processing ? 'Processing...' : 'Pay'} </Button>)}
                        </div>
                    </form>
                </div>
                <div id="payment-request-button" />
            </div>
        )
    }

    return (
        succeeded === true ? renderSuccess() : renderForm()
    )

}

export default injectStripe(CheckoutForm)

