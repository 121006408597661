import React, {useState, useEffect} from 'react'
import { useAuth } from '../Hooks/useAuth'
import { useFetchAllUserListings } from '../Hooks/useFetchAllUserListings'
import { Link } from 'react-router-dom'
import { FormControl, Button, InputGroup, Table } from 'react-bootstrap'
import { formattedDate } from '../../utils/dateFormatter'

const ReportTable = (props) => {
    return (
        <Table striped bordered responsive>
        <thead>
            <tr>
                <th>VRM</th><th>Make</th><th>Model</th><th>Check Date</th>
            </tr>
        </thead>
        <tbody>
            {props.data}
        </tbody>
       </Table>
    )
}

const UserCheckReportList = () => {
    const auth = useAuth()
    const {error, listings} = useFetchAllUserListings(auth.user)
    const [filterListings, setFilterListings] = useState([])
    const [filterValue, setFilterValue] = useState("")

    useEffect(() => {
        if (listings) {
            let vals =   listings.filter(listing => listing.val().vehicleCheckTimestamp)
                                .filter(listing => listing.val().vrm.includes(filterValue.toUpperCase()))                 
            setFilterListings(vals)
        }
    },[listings,filterValue])

    const clearFilter = () => {
       setFilterValue("")
       document.getElementById("filterInput").value = ""
    }

    const filteredListings = filterListings?.map(listing =>
        <tr key={listing.key}>
            <td>
                <Link to={`check-report/${listing.key}`}>{listing.val().vrm}</Link>
            </td>
            <td>
                {listing.val().make}
            </td>
            <td>
                {listing.val().model}
            </td>
            <td>
                {formattedDate(listing.val().vehicleCheckTimestamp)}
            </td>
        </tr>
    )

    const ReportFilter = () => {
        return(
            <InputGroup>
                <FormControl
                    placeholder="Filter by VRM" 
                    id="filterInput" 
                    onChange={(e) => setFilterValue(e.target.value)}
                />
                <InputGroup.Append>
                    <Button onClick={clearFilter}>Clear</Button>
                </InputGroup.Append>
            </InputGroup>
        )
    }

    if (error) {
        return <p>Error getting user checks list</p>
    }
    
    return (
        <div>
            {listings && 
               <ReportFilter/> 
            }
            <ReportTable data={filteredListings}/> 
         </div>
    )
}

export default UserCheckReportList