import React from 'react'
import PageTitle from '../../Shared/Page/pageTitle'

const AboutUs = () => (

    <>
        <PageTitle title='About Us' lead="Transparency for Vehicle Buyers" />

        <div className='container'>
            <h2>
                Who is autoClarity
            </h2>
            <p>
            autoClarity: Car Check &amp; Value app was released to the Apple App Store in 2018 to provide vehicle history checks and valuations in an integrated app 
                for iPhone.
            </p>
            <p>
                Download it from the Apple App Store for free and enter a UK vehicle registration to see full MOT history, vehicle description, economy, previous keepers and so much more.
            </p>
            <p>
                autoClarity: Car Check &amp; Value app is packed full of great features like Automatic Number Plate Recognition and Voice Number Plate Recognition to make looking up vehicles a breeze.
            </p>
            
            <h4>
                Official boring stuff
            </h4>
            <p>
                autoClarity is a trading name of ClearCut IT Limited.
            </p>
            <p>
                Registered Address: 390 Long Lane, London, N2 8JX. Registered in England and Wales number 6337656.
            </p>

            
        </div>
    </>
)

export default AboutUs;