import { useState, useEffect } from 'react'
import firebase from '../Firebase/firebase'


export const useUserAccount = (user) => {

    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [userAccount, setUserAccount] = useState(null)

    useEffect(
        () => {
            if (user) {
                const unsubscribe = firebase
                        .database()
                        .ref('user-account')
                        .child(user.uid)
                        .on('value', snapshot => {
                            setLoading(false)
                            if (snapshot && snapshot.exists()) {
                                console.log('userAcc snap ' + snapshot.key)
                                setUserAccount(snapshot.val())
                            } else {
                                setUserAccount(null)
                            }
                            
                        }, error => {
                            setError(error)
                        })
                return () => unsubscribe()
            }  
        }
        , [user]
    )

    return {
        error,
        loading,
        userAccount
    }
}
