import React, { useState } from 'react'
import { useAuth } from '../Hooks/useAuth'
import { useUserAccount } from '../Hooks/useUserAccount'
import { useFetchAllProducts } from '../Hooks/useFetchAllProducts'
import { ProductCardDeck } from '../Products/productCard'
import { Card, Row, Col } from 'react-bootstrap'
import * as ROUTES from '../../constants/routes'
import { PageSpinner } from '../Shared/Page/pageSpinner'
import { Redirect } from 'react-router-dom'
import './account.css'
import UserCheckReportList from '../CheckReport'


const CreditsCard = (props) => {
    return <Card className='shadow' border={props.variant}>
        <Card.Body>
            <Card.Title className='credit-cards-title'>{props.title}</Card.Title>
            <Card.Text className='credit-cards-text'>{props.credits}</Card.Text>
        </Card.Body>
    </Card>
}


const Account = (props) => {
    const auth = useAuth()
    const {error, loading, userAccount} = useUserAccount(auth.user)
    const { loading: productsLoading, fullCheckProducts, basicCheckProducts, valueVehicleProducts, describeVehicleProducts} = useFetchAllProducts()
    const [product, setProduct] = useState(null)


    const onClickHandler = (productKey) => {
        setProduct({productKey: productKey})
    }

    // check if the user has clicked a Buy button and redirect to the checkout
    if (product) {
        return <Redirect push to={{
            pathname:ROUTES.CHECKOUT,
            state:{
                productKey:product.productKey
             }
           }} />
    }

    if (error) {
        return <p className='text-danger'>
            Oops, something went wrong. Try refreshing the page.
        </p>
    }

    return (
        <div className='container my-3'>
            {loading && <PageSpinner/> }

            {userAccount &&  
                <div className='mt-5'>
                    <h4>Hello {auth.user.displayName || 'there'}! Here is your account balance.</h4>
                    <div className='text-center account-credits mt-3'>
                        <Row>
                            <Col xs={6} md={3}><CreditsCard title="Descriptions" credits={userAccount.describeVehicleCredits} variant='info'/></Col>
                            <Col xs={6} md={3} className='mb-3'><CreditsCard title="Full Checks" credits={userAccount.fullCheckCredits} variant='info'/></Col>
                            <Col xs={6} md={3}><CreditsCard title="Valuations" credits={userAccount.vehicleValuationCredits} variant='info'/></Col>
                            <Col xs={6} md={3}><CreditsCard title="Basic Checks" credits={userAccount.basicCheckCredits} variant='info'/></Col>
                        </Row>

                    </div>    
                </div>
            }

            <div>
                {!productsLoading && 
                    <div>
                        <h2>Top-up Account</h2>
                        <p className='text-muted'>See our products below. Buying credits on our website to use in the app is more cost-effective than buying them in the app.</p>
                    </div>
                }
                
                {!productsLoading && fullCheckProducts && 
                    <div>
                        <h4>Full Checks</h4>
                        <ProductCardDeck products={fullCheckProducts} onClickHandler={onClickHandler}/>
                    </div>
                }  
                
                {!productsLoading && describeVehicleProducts && 
                    <div>
                        <h4>Vehicle Descriptions</h4>
                        <ProductCardDeck products={describeVehicleProducts} onClickHandler={onClickHandler}/>       
                    </div>
                }
                
                {!productsLoading && valueVehicleProducts && 
                    <div>
                        <h4>Valuations</h4>
                        <ProductCardDeck products={valueVehicleProducts} onClickHandler={onClickHandler}/>
                    </div>
                }  
                
                {!productsLoading && basicCheckProducts && 
                    <div>
                        <h4>Basic Checks</h4>
                        <ProductCardDeck products={basicCheckProducts} onClickHandler={onClickHandler}/>
                    </div>    
                } 
            </div>

            <div>
            <h2>Saved Vehicle Checks</h2>
            <UserCheckReportList/>
            </div>    
            

        </div>    
    )
                    
    
}

export default Account