import React from 'react';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck'
import Button from 'react-bootstrap/Button'

import './products.css';


export const ProductCardDeck = (props) => {
    const cards = props.products
                     .sort((a, b) => a.product.displayOrder - b.product.displayOrder)
                     .map(sorted => {
                       // return <ProductCard key={sorted.product.productId} productKey={sorted.productKey} product={sorted.product} buyButtonAction={() => props.onClickHandler(sorted.product.productTypeKey, sorted.productKey)}/>        
                       return <ProductCard key={sorted.productKey} productKey={sorted.productKey} product={sorted.product} buyButtonAction={() => props.onClickHandler(sorted.productKey)}/>        
                      })
    return (
        <CardDeck className='mb-3 text-center border-bottom'>{cards}</CardDeck>
    )
}

const CardListItem = (props) => {
    return <li>{props.credits} x {props.text}</li>
}



const ProductCard = (props) => {

    const product = props.product
    return (
        <Card className='mb-4 shadow'>
            <Card.Header>{product.description}</Card.Header>
            <Card.Body>
                <small className='text-muted'>website price</small>
                <Card.Title className='pricing-card-title'>£{product.webPrice}</Card.Title>
                <ul className='list-unstyled mb-4'>
                    { product.fullCheckCredits && <CardListItem key={'fullchecks'} credits={product.fullCheckCredits} text='Full Checks'/> } 
                    { product.valueVehicleCredits && <CardListItem key={'valuations'} credits={product.valueVehicleCredits} text='Valuations'/> }
                    { product.basicCheckCredits && <CardListItem key={'basicchecks'} credits={product.basicCheckCredits} text='Basic Checks'/> } 
                    { product.describeVehicleCredits && <CardListItem key={'descriptions'} credits={product.describeVehicleCredits} text='Vehicle Descriptions'/> }
                </ul>
                    <Button onClick={props.buyButtonAction}>BUY</Button>
            </Card.Body>
        </Card>
    )
}