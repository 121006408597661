import React from 'react'
import {Helmet} from 'react-helmet'
import Card from 'react-bootstrap/Card'
import PageTitle from '../Shared/Page/pageTitle'
import UlezMapImage from '../../assets/london_ulez_map.png'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const UlezCheck = () => {
    return (
        <>
        <Helmet>
        <title>Ultra Low Emission Zone Checks</title>
        <meta name="description" content="autoClarity: Car Check &amp; Value app for iPhone ULEZ exemption check" />      
        <link rel="canonical" href="https://autoclarity.co.uk/ulez-check" />
        </Helmet>
        <PageTitle title='ULEZ Exemption Check' lead="Check whether your vehicles need to pay the Ultra Low Emission Zone Charge to drive in London"/>
        <Container>
            <Card>
                <Row>
                    <Col md={4}>
                        <Card.Img className="px-1 py-1" src={UlezMapImage} alt="ULEZ map London" />
                    </Col>
                    <Col>
                        <Card.Body md={8}>
                            <Card.Title>Do you need to drive in London? Avoid hefty fines by checking your vehicle for ULEZ exemption.</Card.Title>

                                <ul className='fa-ul'>
                                    <li><FontAwesomeIcon icon="rocket" listItem/>
                                        The Central London Ultra Low Emission Zone (ULEZ), became operational in spring 2019, covering the same area as the Congestion Charging Zone.
                                    </li>
                                    <li><FontAwesomeIcon icon="rocket" listItem/>
                                        For many people, who rarely drive into Central London, this has not affected them.
                                    </li>
                                    <li><FontAwesomeIcon icon="rocket" listItem/>
                                        In autumn 2021 a significant widening of the zone occurs, where the ULEZ will cover the entire area within the North and South Circular Roads.
                                    </li>
                                    <li><FontAwesomeIcon icon="rocket" listItem/>
                                        <strong>If you drive a diesel vehicle manufactured before 2016, or a petrol vehicle manufactured before 2006, it is probably not compliant, and you will have to pay the charge or make a change.</strong>
                                    </li>
                                </ul>
                                

                        </Card.Body>       
                    </Col>
                </Row>
            </Card>

            <h2 className='mt-5 mb-2'>What is the London ULEZ?</h2>
            <span className='align-middle'>
                <p>
                    The London Ultra Low Emission Zone is an initiative from the Mayor of London to improve the air quality and address pollution, 
                    much of it caused by emissions from vehicles.
                </p>
                <p>
                    It operates 24 hours a day 7 days a week.
                </p>
                <p>
                    It currently covers the same area as the Congestion Charging Zone, but is due to expand widely in October 2021.
                </p>
            </span>

            <h2 className='mt-5 mb-2'>How Do I Check My Vehicle?</h2>
            <span className='align-middle'>
                <p>
                    autoClarity: Car Check &amp; Value app app will indicate whether the combination of your vehicle's fuel type and Euro status meets the standard. Download
                    the app and enter your vehicle's registration plate number to find out.
                </p>
                <p>
                autoClarity: Car Check &amp; Value app app uses DVLA data to determine compliance. There can be exceptions, so contact the DVLA if you think there is 
                    something wrong.
                </p>
            </span>


            <h2 className='mt-5 mb-2'>What are the Charges?</h2>
            <span className='align-middle'>
                <p>
                    For most non-compliant vehicles - cars, vans, motorcycles - the <strong>daily charge is £12.50.</strong>
                </p>
                <p>
                    For non-compliant vehicles over 3.5 tonnes and buses and coaches over 5 tonnes the <strong>daily charge is £100.</strong>
                </p>
            </span>

            <h2 className='mt-5 mb-2'>I Live in the Extended Zone and Drive a Non-compliant Vehicle. Am I Exempt from the Charge?</h2>
            <span className='align-middle'>
                <p>
                    Probably not. Most residents will be expected to change their vehicle to one that meets the standards, or, pay the
                    charge.
                </p>
                <p>
                    There are some exemptions for London-licensed Taxis and historic vehicles built before 1979. There is an extended period of
                    grace for vehicles registered to a disabled person.
                </p>
                <p>
                    Full details for exemptions can be found <a href="https://tfl.gov.uk/modes/driving/ultra-low-emission-zone/discounts-and-exemptions?intcmp=52218">here</a>
                </p>
            </span>


        </Container>
            
        </>
    )
}

export default UlezCheck